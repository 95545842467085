import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageBoxComponent } from './message-box.component';

@NgModule({
    imports: [CommonModule],
    declarations: [MessageBoxComponent],
    exports: [MessageBoxComponent, CommonModule, FormsModule],
    providers: []
})
export class MessageBoxModule { }