import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as Script from "../_shared-modules/scripts/script";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ServiceBase, ApiRequest, ApiResponse } from "../base";
import { DomesticMoneyTransferModel } from "../_models/DomesticMoneyTransferModel";
import { SiteVariablesModel } from "../_models/SiteVariablesModel";
import { MessageModel } from "../_models/MessageModel";
import { PosModel } from "../_models/PosModel";
import { Router, ActivatedRoute } from "@angular/router";
import { BusinessUserCollectionDetail } from "../_models/BusinessUserCollectionDetail";

@Component({
  templateUrl: "./collection.component.html",
  providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class BusinessUserCollectionComponent implements OnInit {
  calculateDomesticForms: FormGroup;
  markers: any = [];
  PostUrl: string;
  sessionDateTime: any;
  lastAction: any;
  warningMessage: string = "";
  guid: string = "";
  // collectionForm: FormGroup;
  collectionDetail: BusinessUserCollectionDetail;
  commissionAmount: number;
  bsmvAmount: number;
  totalAmount: number;
  totalCommission: number;
  isAcceptTerms: boolean = false;
  Loading: boolean = false;

  public cardInfos: {
    CcOwnerName: string;
    CcNumber: string;
    Cvc: string;
    CreditLastDate: string;
  };

  constructor(
    public formBuilder: FormBuilder,
    public serviceBase: ServiceBase,
    public moneyTransfer: DomesticMoneyTransferModel,
    public siteVariablesModel: SiteVariablesModel,
    private messageModel: MessageModel,
    private posModel: PosModel,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.calculateDomesticForms = this.formBuilder.group({
      Amount: [null, Validators.compose([Validators.required])],
    });
    this.siteVariablesModel.setLanguage();
    this.PostUrl = "";
    this.checkLastAction();
    this.initListener();
    this.initIntervalLastAction();

    this.route.queryParams.subscribe((params) => {
      this.guid = params["rn"];
    });

    this.cardInfos = {
      CcNumber: "",
      CcOwnerName: "",
      CreditLastDate: "",
      Cvc: ""
    };

    // this.collectionForm = this.formBuilder.group({
    //   'senderNameSurname': [null, Validators.compose([Validators.required])],
    //   'senderGsmNumber': [null,Validators.compose([Validators.required,Validators.minLength(10),Validators.maxLength(10)])],
    //   'senderEmail': [null, Validators.compose([Validators.required])],
    // });
  }

  ngOnInit() {
    if (this.guid) {
      this.getCollection();
      // this.onPaymentChange();
      // Script.moneyTransfer();
      // Script.moneyTransferCalculate(this.siteVariablesModel.language);
      // Script.selectPicker();
      // window["dataLayer"] = window["dataLayer"] || {};
      // window["dataLayer"].push({
      //   event: "IBANCompleteTransfer",
      // });
    } else {
      this.goToHome();
    }
  }

  getCollection() {
    this.Loading = true;
    var request = new ApiRequest();
    request.AddDataObjectToRequest("MethodPath", "BusinessUserCollection/GetBusinessUserCollectionRequestByIdentifier");
    request.AddDataObjectToRequest("BusinessUserCollectionRequest", this.guid);
    this.serviceBase.ExecuteForGonderAL(request, "BusinessUserCollectionRequest").then(() => {
      if (this.serviceBase.apiResponse.IsSuccessful) {
        this.collectionDetail = this.serviceBase.apiResponse.ResponseData.BusinessUserCollectionRequest;
        this.commissionAmount = this.serviceBase.apiResponse.ResponseData.CommissionAmount;
        this.bsmvAmount = this.serviceBase.apiResponse.ResponseData.BSMVAmount;
        this.totalAmount = this.serviceBase.apiResponse.ResponseData.TotalAmount;
        this.totalCommission = this.serviceBase.apiResponse.ResponseData.TotalCommission;
        this.Loading = false;
      } else {
        this.messageModel.showError("Bir sorun oluştu", this.serviceBase.apiResponse.ErrorMessage);
        this.Loading = false;
        return;
      }
    });
  }

  onPaymentChange() {
    if (!this.moneyTransfer.IsVerifyTransaction) {
      Script.removeProcessing($("#pay-point .local").parents("form"));
      Script.recipientBlockGrant(false);
      Script.recipientBlockExpand(false);
      Script.resetMoneyTransfer();
      this.calculateDomesticForms.reset();
      this.moneyTransfer.TotalCommissionAmount = 0;
    }
  }

  calculateCommission() {
    if (this.collectionDetail.Amount > 19999) {
      this.messageModel.showError(
        "Bir sorun oluştu",
        "İşlem limiti 19999 TL'dir."
      );
    } else if (this.collectionDetail.Amount < 5) {
      this.messageModel.showError(
        "Bir sorun oluştu",
        "IBAN'a 5 TL altı gönderim yapılamamaktadır."
      );
    } else {
      this.Loading = true;
      this.totalCommission = 0;

      var request = new ApiRequest();
      request.AddDataObjectToRequest("MethodPath", "BusinessUserCollection/GetCommission");
      // request.AddDataObjectToRequest("BusinessUserId", this.collectionDetail.BusinessUserId);
      // request.AddDataObjectToRequest("Amount", this.collectionDetail.Amount);
      request.AddDataObjectToRequest("BusinessUserGetCommissionViewModel", {
        BusinessUserId: this.collectionDetail.BusinessUserId,
        Amount: this.collectionDetail.Amount
      });

      this.serviceBase.ExecuteForGonderAL(request, "BusinessUserGetCommissionViewModel").then(() => {
        if (this.serviceBase.apiResponse.IsSuccessful) {
          this.commissionAmount = this.serviceBase.apiResponse.ResponseData.CommissionAmount;
          this.bsmvAmount = this.serviceBase.apiResponse.ResponseData.BSMVAmount;
          this.totalAmount = this.serviceBase.apiResponse.ResponseData.TotalAmount;
          this.totalCommission = this.serviceBase.apiResponse.ResponseData.TotalCommission;
          this.Loading = false;
          // console.log(this.serviceBase.apiResponse.ResponseData);
          // this.collectionDetail = this.serviceBase.apiResponse.ResponseData.BusinessUserCollectionRequest;
          // this.commissionAmount = this.serviceBase.apiResponse.ResponseData.CommissionAmount;
          // this.bsmvAmount = this.serviceBase.apiResponse.ResponseData.BSMVAmount;
          // this.totalAmount = this.serviceBase.apiResponse.ResponseData.TotalAmount;
          // this.totalComission = this.serviceBase.apiResponse.ResponseData.TotalCommission;
          // this.collectionDetail = this.serviceBase.apiResponse.ResponseData.BusinessUserCollectionRequestViewModel;
        } else {
          this.messageModel.showError("Bir sorun oluştu", this.serviceBase.apiResponse.ErrorMessage);
          this.Loading = false;
          return;
        }
      });
    }

    // if (valid) {
    //   if (formData.Amount > 1000) {
    //     this.messageModel.showError(
    //       "Bir problem var",
    //       "İşlem limiti 1000 TL'dir."
    //     );
    //   } else if (
    //     this.moneyTransfer.PaymentType == "bank-account" &&
    //     formData.Amount < 5
    //   ) {
    //     this.messageModel.showError(
    //       "Bir problem var",
    //       "IBAN'a 5 TL altı gönderim yapılamamaktadır."
    //     );
    //   } else {
    //     Script.addProcessing($("#pay-point .local").parents("form"));
    //     this.moneyTransfer.TotalCommissionAmount = 0;
    //     var request = new ApiRequest();
    //     request.AddDataObjectToRequest(
    //       "MethodPath",
    //       "WebMoneyTransferController/SelectCommissionReferenceTableByInterval"
    //     );
    //     request.AddDataObjectToRequest("Amount", formData.Amount);
    //     request.AddDataObjectToRequest(
    //       "PaymentType",
    //       this.moneyTransfer.PaymentType
    //     );
    //     this.serviceBase.Execute(request).then(() => {
    //       if (this.serviceBase.apiResponse.IsSuccessful) {
    //         this.moneyTransfer.CommissionAmount = this.serviceBase.apiResponse.ResponseData.CommissionAmount;
    //         this.moneyTransfer.BSMVAmount = this.serviceBase.apiResponse.ResponseData.BSMVAmount;
    //         this.moneyTransfer.Amount = formData.Amount;
    //         this.moneyTransfer.TotalAmount = this.serviceBase.apiResponse.ResponseData.TotalAmount;
    //         this.moneyTransfer.TotalCommissionAmount = this.serviceBase.apiResponse.ResponseData.TotalCommissionAmount;
    //         this.moneyTransfer.TransactionSessionId = this.serviceBase.apiResponse.ResponseData.SessionId;
    //         this.sessionDateTime = this.serviceBase.apiResponse.ResponseData.SessionTime;
    //         Script.removeProcessing($("#pay-point .local").parents("form"));
    //         Script.yourDetailsBlockGrant(true);
    //         Script.yourDetailsBlockExpand(true);

    //         if (this.moneyTransfer.PaymentType == "pay-point") {
    //           window["dataLayer"].push({
    //             event: "PaymentCalculate",
    //           });
    //         } else {
    //           window["dataLayer"].push({
    //             event: "IBANCalculate",
    //           });
    //         }
    //       } else {
    //         this.messageModel.showError(
    //           "Bir problem var",
    //           "Bu tutar için ücret hesaplanamadı."
    //         );
    //         Script.removeProcessing($("#pay-point .local").parents("form"));
    //         Script.yourDetailsBlockGrant(false);
    //       }
    //     });
    //   }
    // }
  }

  makeRoutingTransfer() {
    if (!this.isAcceptTerms) {
      this.messageModel.showError("Bir sorun oluştu", "Lütfen kullanım koşullarını kabul ediniz.");
      return;
    }

    let errors: string = "";
    if (this.cardInfos.CcOwnerName === "") {
      errors += "Kart Sahibinin Adı alanı boş geçilemez! \n";
    }
    if (this.cardInfos.CcNumber === "") {
      errors += "Kart No alanı boş geçilemez! \n";
    }
    if (this.cardInfos.CreditLastDate === "") {
      errors += "Geçerlilik Tarihi alanı boş geçilemez! \n";
    }
    if (this.cardInfos.Cvc === "") {
      errors += "CVC alanı boş geçilemez! \n";
    }

    if (errors !== "") {
      this.messageModel.showError("Lütfen aşağıdaki hataları kontrol ediniz!", errors);
      return;
    }

    // console.log("test");

    this.posModel.CcOwnerName = this.cardInfos.CcOwnerName;
    this.posModel.CcNumber = this.cardInfos.CcNumber;
    this.posModel.Cvc = this.cardInfos.Cvc;
    this.posModel.CreditLastDate = this.cardInfos.CreditLastDate;
    this.posModel.ExpireMonth = this.posModel.CreditLastDate.slice(0, 2);
    this.posModel.ExpireYear = this.posModel.CreditLastDate.slice(2, 4);
    this.posModel.Amount = this.collectionDetail.Amount;
    this.posModel.Email = "info@gonder-al.com";
    this.posModel.Language = "tr";
    this.posModel.Currency = "949";
    this.posModel.TransactionType = "Auth";
    this.posModel.CardType = "1";
    this.posModel.TransactionChannelType = "BusinessUserWeb";
    this.posModel.IsBusinessUser = true;
    this.posModel.BusinessUserCollectionRequestId = this.collectionDetail.Id;

    this.router.navigate(["/payment"]);
  }

  // test() {
  //   var request = new ApiRequest();
  //     request.AddDataObjectToRequest(
  //       "MethodPath",
  //       "BusinessUserCollection/ConfirmBusinessUserCollectionRequest"
  //     );
  //     request.AddDataObjectToRequest("Input", this.posModel);
  //     this.serviceBase.ExecuteForGonderAL(request, "Input").then(() => {
  //       var RedirectDataKeyValuePair = this.serviceBase.apiResponse.ResponseData.RedirectDataKeyValuePair;
  //       this.PostUrl = this.serviceBase.apiResponse.ResponseData.PostUrl;
  //       this.moneyTransfer.TransactionSessionId = this.serviceBase.apiResponse.ResponseData.TransactionSessionId;
  //       // console.log(this.PostUrl);

  //       // this.form.nativeElement.action = this.PostUrl;

  //       // for (let key in RedirectDataKeyValuePair) {
  //       //     var input = document.createElement("input");
  //       //     input.type = "hidden";
  //       //     input.name = key;
  //       //     input.value = RedirectDataKeyValuePair[key];
  //       //     this.form.nativeElement.appendChild(input);
  //       // }

  //       // this.form.nativeElement.submit();
  //     });
  // }

  resetModel() {
    this.sessionDateTime = undefined;
    this.moneyTransfer.PaymentType = "";
    this.moneyTransfer.Amount = undefined;
    this.moneyTransfer.CommissionAmount = 0;
    this.moneyTransfer.BSMVAmount = 0;
    this.moneyTransfer.TotalAmount = 0;
    this.moneyTransfer.SenderName = undefined;
    this.moneyTransfer.SenderSurname = undefined;
    this.moneyTransfer.SenderOccupation = "";
    this.moneyTransfer.SenderReceiverRelationship = "";
    this.moneyTransfer.PurposeofTransaction = "";
    this.moneyTransfer.SenderTCKN = undefined;
    this.moneyTransfer.SenderBirthDate = undefined;
    this.moneyTransfer.SenderGSMNumber = undefined;
    this.moneyTransfer.SenderAddress = undefined;
    this.moneyTransfer.SenderSecondAddress = undefined;
    this.moneyTransfer.CityId = "";
    this.moneyTransfer.DistrictId = "";
    this.moneyTransfer.ZipCode = undefined;
    this.moneyTransfer.ReceiverName = undefined;
    this.moneyTransfer.ReceiverSurname = undefined;
    this.moneyTransfer.ReceiverOccupation = "";
    this.moneyTransfer.ReceiverGSMNumber = undefined;
    this.moneyTransfer.TransactionSessionId = undefined;
    this.posModel.Amount = undefined;
    this.posModel.CcNumber = undefined;
    this.posModel.CreditLastDate = undefined;
    this.posModel.CcOwnerName = undefined;
    this.posModel.Cvc = undefined;
    this.lastAction = undefined;
    this.moneyTransfer.TotalCommissionAmount = 0;
    this.moneyTransfer.ReceiverTCKN = undefined;
    this.moneyTransfer.ReceiverIBAN = "TR";
    this.calculateDomesticForms.reset({ Amount: undefined });
    this.moneyTransfer.IsCustomerVerified = true;
    this.moneyTransfer.IsVerifyTransaction = false;
    this.moneyTransfer.SenderTcknValidationStatus = false;
    this.moneyTransfer.ReceiverTcknValidationStatus = false;
    Script.resetMoneyTransfer();
  }

  //#region control timer
  initListener() {
    document.body.addEventListener("click", () => this.reset());
  }

  initIntervalLastAction() {
    setInterval(() => {
      this.checkLastAction();
    }, 5 * 1000);
  }

  checkLastAction() {
    let lastActionSeconds;
    let dateNow = new Date();
    let dateNowSeconds = dateNow.getTime() / 1000;

    //#region control Last Action
    if (
      this.lastAction != undefined &&
      this.moneyTransfer.TransactionSessionId != undefined
    ) {
      lastActionSeconds = this.lastAction.getTime() / 1000;

      var diffSeconds = dateNowSeconds - lastActionSeconds;
      // 4. dakka
      if (diffSeconds < 300 && diffSeconds > 240) {
        $("#request-warning-lastaction").modal("show");
        this.warningMessage =
          "Uzun süre işlem yapmadığınız için oturumunuz sonlandırılacaktır. Devam etmek istiyor musunuz?";
      }

      // 5.dakka
      if (diffSeconds > 300) {
        $("#request-warning-lastaction").modal("hide");
        this.messageModel.showError(
          "",
          "Uzun süre işlem yapmadığınız için oturumunuz güvenlik sebebiyle sonlandırılmıştır."
        );
        window.location.reload();
      }
    }
    //#endregion

    //#region control session Time Expried

    if (
      this.moneyTransfer.Amount != undefined &&
      this.moneyTransfer.TransactionSessionId != undefined
    ) {
      if (new Date().getTime() > new Date(this.sessionDateTime).getTime()) {
        $("#request-warning").modal("hide");
        this.messageModel.showError(
          "",
          "Size ayrılan süre içerisinde işlemi tamamlamadığınız için oturumunuz güvenlik sebebiyle sonlandırılmıştır."
        );
        window.location.reload();
      }

      var diffMs = Math.abs(
        new Date(this.sessionDateTime).getTime() - new Date().getTime()
      ); // milliseconds between date1 & date2

      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

      if (diffMins <= 1) {
        $("#request-warning").modal("show");
        this.warningMessage =
          "1 dakika sonra oturumunuz sonlandırılacaktır. İşleme devam etmek istiyor musunuz?";
      }
    }

    //#endregion
  }

  sessionExtendTime() {
    var request = new ApiRequest();
    request.AddDataObjectToRequest(
      "MethodPath",
      "WebMoneyTransferController/UpdateDomesticMoneyTransferWebSessionExpiredTimeById"
    );
    request.AddDataObjectToRequest(
      "sessionId",
      this.moneyTransfer.TransactionSessionId
    );
    this.serviceBase.Execute(request).then(() => {
      this.sessionDateTime = this.serviceBase.apiResponse.ResponseData.SessionTime;
      $("#request-warning").modal("hide");
    });
  }

  closeModal() {
    $("#request-warning-lastaction").modal("hide");
  }

  refresh(): void {
    window.location.reload();
  }

  reset() {
    this.lastAction = new Date();
  }

  //#endregion

  //#region for future use - show receiver nearest pay-point

  findNearestLocation() {
    this.markers = this.siteVariablesModel.branches;
    this.siteVariablesModel.branches = this.transformSearch(
      this.markers,
      {
        LegalName: this.moneyTransfer.ReceiverLocation,
        LocationCityName: this.moneyTransfer.ReceiverLocation,
      },
      false
    );

    if (
      this.siteVariablesModel.branches == undefined ||
      this.siteVariablesModel.branches.length < 1
    ) {
      //this.siteVariablesModel.branches = this.siteVariablesModel.originalBranches;
      // this.messageModel.showError("Bir problem var", "Aradığınız kriterlere uygun herhangi bir GönderAl bayisi bulunmamaktadır.Arama kısmını değiştirip tekrar deneyiniz.");
    }
  }

  transformSearch(items: any, filter: any, defaultFilter: boolean): any {
    if (!filter) {
      return items;
    }
    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter((item) =>
          filterKeys.reduce(
            (x, keyName) =>
              (x && new RegExp(filter[keyName], "gi").test(item[keyName])) ||
              filter[keyName] == "",
            true
          )
        );
      } else {
        return items.filter((item) => {
          return filterKeys.some((keyName) => {
            return (
              new RegExp(filter[keyName], "gi").test(item[keyName]) ||
              filter[keyName] == ""
            );
          });
        });
      }
    }
  }

  //#endregion

  setVerifyTransaction() {
    this.moneyTransfer.IsVerifyTransaction = true;
    $("#request-warning-verify").modal("hide");
    this.moneyTransfer.PaymentType = "bank-account";
    this.moneyTransfer.CommissionAmount = 0;
    this.moneyTransfer.BSMVAmount = 0;
    this.calculateDomesticForms.controls["Amount"].setValue(20);
    this.calculateDomesticForms.controls["Amount"].disable();
    $("#PaymentSelect").val("bank-account").trigger("change");
    this.moneyTransfer.Amount = 20;
    this.moneyTransfer.TotalAmount = 20;
    this.moneyTransfer.TotalCommissionAmount = 0;
    this.moneyTransfer.ReceiverGSMNumber = this.moneyTransfer.SenderGSMNumber;
    this.moneyTransfer.ReceiverName = this.moneyTransfer.SenderName;
    this.moneyTransfer.ReceiverSurname = this.moneyTransfer.SenderSurname;
    this.moneyTransfer.ReceiverTCKN = this.moneyTransfer.SenderTCKN;
    this.moneyTransfer.ReceiverTcknValidationStatus = true;
  }

  goToHome() {
    this.router.navigate(["/"]);
  }

  closeVerifyModal() {
    $("#request-warning-verify").modal("hide");
  }
}
