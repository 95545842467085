/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./applink.component";
var styles_AppLinkComponent = [];
var RenderType_AppLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppLinkComponent, data: {} });
export { RenderType_AppLinkComponent as RenderType_AppLinkComponent };
export function View_AppLinkComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AppLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AppLinkComponent_0, RenderType_AppLinkComponent)), i0.ɵdid(1, 114688, null, 0, i1.AppLinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLinkComponentNgFactory = i0.ɵccf("ng-component", i1.AppLinkComponent, View_AppLinkComponent_Host_0, {}, {}, []);
export { AppLinkComponentNgFactory as AppLinkComponentNgFactory };
