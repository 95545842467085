import { Component, OnInit, Input } from '@angular/core';
import { ApiRequest, ApiResponse, ServiceBase } from 'src/app/base';
import { BusinessUserApplyModel } from 'src/app/_models/BusinessUserApplyModel';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';
import { MessageModel } from 'src/app/_models/MessageModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'businessuser-companyinfo',
  templateUrl: './businessuser-companyinfo.component.html',
  providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class BusinessUserUserCompanyComponent implements OnInit {

  companyForm: FormGroup;
  formValidation = false;

  constructor(public serviceBase: ServiceBase, public businessUserApply: BusinessUserApplyModel, private messageModel: MessageModel,
    private siteVariablesModel: SiteVariablesModel, public formBuilder: FormBuilder) {
    this.siteVariablesModel.setLanguage();
    this.companyForm = this.formBuilder.group({
      'businesstitle': [null, Validators.compose([Validators.required, Validators.minLength(5)])],
      'taxoffice': '',//[null, Validators.compose([Validators.required])]
      'taxId': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(11)])],
      'activitysubject': [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
      'address': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
      'estimateMonthlyCollection': [null, Validators.compose([Validators.required, Validators.maxLength(5)])],
      'estimateMonthlyShipping': [null, Validators.compose([Validators.required, Validators.maxLength(5)])],
      'description': [null, Validators.compose([Validators.required, Validators.minLength(5)])],
    });

  }

  ngOnInit() {
    this.companyForm.reset();
    const invalidChars = [
      "-",
      "e",
    ];

    document.getElementById("inputBox").addEventListener("keydown", function (e) {


      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

    document.getElementById("inputBoxCollect").addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

    document.getElementById("inputBoxShipping").addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

  }





  checkSameUpCheck(e) {
    if (e.target.checked) {

      if (this.businessUserApply.Name == "" || this.businessUserApply.Surname == "") {
        $("#tnc-checkbox-transfer").prop('checked', false);
        this.messageModel.showError("", "Lütfen Ad, Soyad ve Tckn bilgilerini doldurunuz.");
        return;
      }
      this.companyForm.controls['businesstitle'].setValue(this.businessUserApply.Name + " " + this.businessUserApply.Surname);
      // this.companyForm.controls['taxId'].setValue(this.businessUserApply.Tckn);
      this.companyForm.controls['taxoffice'].setValue('');
      this.companyForm.controls['taxoffice'].disable();
    } else {
      this.companyForm.controls['businesstitle'].setValue('');
      this.companyForm.controls['taxId'].setValue('');
      this.companyForm.controls['taxoffice'].enable();
    }
  }

}
