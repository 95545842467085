import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { BusinessUserApplyModel } from '../_models/BusinessUserApplyModel';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';
import { BusinessUserVerifyGsmModel } from '../_models/BusinessUserVerifyGsmModel';
//import { BusinessUserVerifyTcknModel } from '../_models/BusinessUserVerifyTcknModel';
import { BusinessUserSendVerificationCodeModel } from '../_models/BusinessUserSendVerificationCodeModel';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  templateUrl: './businessuser.component.html',
  providers: [ApiRequest, ApiResponse, ServiceBase],
})

export class BusinessMemberComponent implements OnInit {
  otpCodeComponentShow: boolean = true;
  sendButtonComponentShow: boolean = false;
  otpCodeForms: FormGroup;
  count: string = '0';
  consTimer: any;
  otpCodeAgainComponentShow: boolean = true;

  constructor(public formBuilder: FormBuilder, public serviceBase: ServiceBase,
    public businessUserApply: BusinessUserApplyModel,
    public businessUserVerifyGsm: BusinessUserVerifyGsmModel,
    //public verifyTcknModel: BusinessUserVerifyTcknModel,
    public businessUserSendVerifyVode: BusinessUserSendVerificationCodeModel,
    private messageModel: MessageModel,
    public siteVariablesModel: SiteVariablesModel,
    private router: Router) {
    this.otpCodeForms = this.formBuilder.group({
      'OtpCodeValue': [null, Validators.compose([Validators.required])]
    });
    this.siteVariablesModel.setLanguage();
  }

  ngOnInit() {
    this.resetModel();
  }

  onStartTimer() {
    // timer işlemi
    this.consTimer = interval(1000);
    let remaingTime = 180;

    this.consTimer.subscribe((count) => {
      let lastime = (remaingTime - parseInt(count)).toString()

      $("#otpCodeTimer").val(lastime);

      if (parseInt(lastime) == 0) {
        this.destroyTimer();
      }
    });

  }

  destroyTimer() {
    this.otpCodeAgainComponentShow = false;
    this.otpCodeComponentShow = true;
    this.consTimer.unsubscribe();
  }

  businessUserApplyRequestOnClick() {
    if (this.businessUserApply.CommunicationGsmNumber != "" && this.businessUserApply.CommunicationEmail != "" &&
      this.businessUserApply.Name != "" && this.businessUserApply.LegalName != "" && this.businessUserApply.TaxId != "") {
      if (this.businessUserApply.CommunicationGsmNumber.substr(0, 1) != "5") {
        this.messageModel.showError("", "Girmiş oldugunuz telefon numarası 5 ile başlamalıdır.");
        return;
      }

      $("#otpCodeInput").val('');

      this.businessUserApply.Loading = true;
      // this.businessUserSendVerifyVode.Tckn = this.businessUserApply.Tckn;
      this.businessUserSendVerifyVode.CommunicationGsmNumber = this.businessUserApply.CommunicationGsmNumber;
      var request = new ApiRequest();
      request.AddDataObjectToRequest("MethodPath", "BusinessUser/SendVerificationCodeBusinessUser");
      request.AddDataObjectToRequest("BusinessUserSendVerificationCodeModel", this.businessUserSendVerifyVode);

      this.serviceBase.ExecuteForGonderAL(request, "BusinessUserSendVerificationCodeModel").then(() => {
        if (this.serviceBase.apiResponse.IsSuccessful) {

          this.businessUserApply.Loading = false;
          this.otpCodeComponentShow = false;
          this.sendButtonComponentShow = true;
          this.otpCodeAgainComponentShow = true;

          this.businessUserVerifyGsm.Guid = this.serviceBase.apiResponse.ResponseData.Guid;
          this.messageModel.showSuccess("Doğrulama Kodu", "Lütfen telefonunuza gelen doğrulama kodunu girerek devam ediniz.");

          this.onStartTimer();

          return;
        }
        else {
          this.businessUserApply.Loading = false;
          this.messageModel.showError("Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.", this.serviceBase.apiResponse.ErrorMessage);
          return;
        }

      });

    } else {
      this.businessUserApply.Loading = false;
      this.messageModel.showError("Eksik bilgi", "Lütfen zorunlu alanları doldurunuz.");
      return;
    }
  }

  OtpCodeVerifyGsmNumber(formData, valid: boolean) {
    if (valid) {
      if (formData.OtpCodeValue) {
        $("#sendOtpButtonId").css('display', 'none');
        $("#otpCodeInput").val('');
        this.businessUserApply.Loading = true;
        this.businessUserVerifyGsm.OtpCode = this.businessUserApply.OtpCode;

        var request = new ApiRequest();
        request.AddDataObjectToRequest("MethodPath", "BusinessUser/VerifyGSMNumberBusinessUser");
        request.AddDataObjectToRequest("BusinessUserVerifyGsmModel", this.businessUserVerifyGsm);
        this.serviceBase.ExecuteForGonderAL(request, "BusinessUserVerifyGsmModel").then(() => {
          if (this.serviceBase.apiResponse.IsSuccessful) {
            //this.TcknVerifyFunction();
            this.ApplyBusinessUser();
          }
          else {
            this.businessUserApply.Loading = false;
            $("#sendOtpButtonId").css('display', 'block');
            this.messageModel.showError("Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.", this.serviceBase.apiResponse.ErrorMessage);
            return;
          }
        });

      } else {
        $("#sendOtpButtonId").css('display', 'block');
        this.businessUserApply.Loading = false;
        this.messageModel.showError("Eksik bilgi", "Lütfen dogrulama kodu bilgisini giriniz.");
        return;
      }
    }
  }

  // TcknVerifyFunction(){
  //     if(this.businessUserApply.Guid != "" || this.businessUserApply.Guid != undefined || this.businessUserApply.Guid != null){
  //       this.verifyTcknModel.Guid = this.businessUserVerifyGsm.Guid;
  //       this.verifyTcknModel.Tckn = this.businessUserApply.Tckn;
  //       this.verifyTcknModel.Name = this.businessUserApply.Name;
  //       this.verifyTcknModel.Surname = this.businessUserApply.Surname;
  //       this.verifyTcknModel.GsmNumber = this.businessUserApply.CommunicationGsmNumber;
  //       var request = new ApiRequest();
  //           request.AddDataObjectToRequest("MethodPath", "BusinessUser/VerifyTCKNBusinessUser");
  //           request.AddDataObjectToRequest("BusinessUserVerifyTcknModel", this.verifyTcknModel);
  //           this.serviceBase.ExecuteForGonderAL(request,"BusinessUserVerifyTcknModel").then(() => {
  //               if (this.serviceBase.apiResponse.IsSuccessful) {
  //                   this.ApplyBusinessUser();
  //                   return;
  //               }
  //               else {
  //                 this.businessUserApply.Loading = false;
  //                   this.messageModel.showError("Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.", this.serviceBase.apiResponse.ErrorMessage);
  //                   return;
  //               }
  //           });

  //     }else{
  //       this.businessUserApply.Loading = false;
  //       this.messageModel.showError("Eksik bilgi", "Eksik bir bilgi mevcuttur.");
  //       return;
  //     }
  // }

  ApplyBusinessUser() {
    try {
      this.businessUserApply.Guid = this.businessUserVerifyGsm.Guid;
      this.businessUserApply.TaxId = this.businessUserApply.TaxId.toString();
      var request = new ApiRequest();
      request.AddDataObjectToRequest("MethodPath", "BusinessUser/ApplyBusinessUser");
      request.AddDataObjectToRequest("BusinessUserApplyModel", this.businessUserApply);
      this.serviceBase.ExecuteForGonderAL(request, "BusinessUserApplyModel").then(() => {
        if (this.serviceBase.apiResponse.IsSuccessful) {
          this.businessUserApply.Loading = false;
          this.messageModel.showSuccess("Başarılı", "Başvuru talebiniz alınmıştır. En kısa zamanda satış temsilcimiz sizinle iletişime geçecektir.");

          setTimeout(function () {
            window.location.replace('/');
          }, 4000);
        }
        else {
          this.businessUserApply.Loading = false;
          this.messageModel.showError("Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.", this.serviceBase.apiResponse.ErrorMessage);
          return;
        }
      });
    }
    catch (error) {
      this.businessUserApply.Loading = false;
      this.messageModel.showError("Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.", "Beklenmedik bir hata oluştu.");
      return;
    }
  }

  resetModel() {
    this.businessUserApply.Name = "";
    this.businessUserApply.Surname = "";
    this.businessUserApply.CommunicationGsmNumber = "";
    this.businessUserApply.CommunicationEmail = "";
    // this.businessUserApply.Tckn = "";
    this.businessUserApply.TaxId = "";
    this.businessUserApply.TaxOffice = "";
    this.businessUserApply.LegalName = "";
    this.businessUserApply.ActivitySubject = "";
    this.businessUserApply.Description = "";
    this.businessUserApply.EstimateMonthlyCollection = null;
    this.businessUserApply.EstimateMonthlyShipping = null;
    this.businessUserApply.OtpCode = null;
  }

}
