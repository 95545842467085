import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[upperCase]'
})
export class UpperCaseDirective {

  constructor(private el: ElementRef, private control: NgControl) {

  }

  @HostListener('input', ['$event']) onEvent($event) {
    let upper = this.el.nativeElement.value.replace(/i/g, "İ").toUpperCase();
    this.control.control.setValue(upper);
  }
}