import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as Script from '../_shared-modules/scripts/script';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './verifymail.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})

export class VerifyMailComponent implements OnInit {

    verifyReference: string;
    verifyReferenceValid: boolean;

    constructor(public formBuilder: FormBuilder, public serviceBase: ServiceBase, public siteVariablesModel: SiteVariablesModel,
        private messageModel: MessageModel, private router: Router, private activatedRoute: ActivatedRoute) {

        this.siteVariablesModel.setLanguage();
        this.verifyReferenceValid = false;
    }

    ngOnInit() {

        this.verifyReference = undefined;
        this.verifyReferenceValid = false;

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['ref'] != undefined)
                this.verifyReference = params['ref'];
        });

        var request = new ApiRequest();
        request.AddDataObjectToRequest("MethodPath", "MobileCustomerEmailVerificationController/SelectVerificationReference");
        request.AddDataObjectToRequest("VerificationReference", this.verifyReference);
        this.serviceBase.Execute(request).then(() => {
            if (!this.serviceBase.apiResponse.IsSuccessful) {
                this.showError(this.serviceBase.apiResponse.ErrorCode, this.serviceBase.apiResponse.ErrorMessage);
            }
            else {
                this.verifyReferenceValid = true;
            }
        });

    }

    goToHome() {
        this.router.navigate(['/']);
    }

    showError(errorCode: string, errorMessage: string) {
        if (errorCode == "WEB002") {
            this.router.navigate(['/not-found']);
        }
        else {
            this.messageModel.showError("Bir Problem Var", errorMessage);
        }

    }


}