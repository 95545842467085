import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UppercaseModule } from '../uppercase/uppercase.module';
import { BusinessUserUserInfoComponent } from './businessuser-userinfo.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UppercaseModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [BusinessUserUserInfoComponent],
  exports: [BusinessUserUserInfoComponent, CommonModule],
  providers: []
})
export class BusinessUserUserInfoModule { }