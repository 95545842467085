import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './applink.component.html',
})
export class AppLinkComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        $(document).ready(function () {
            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.gonderal.android ';
            }
            if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                window.location.href = 'https://apps.apple.com/us/app/gonderal/id1448242423';
            }
        });

    }
}