import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './documents.component.html',
})
export class DocumentsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}