<form [formGroup]="businessUserForm">

    <div class="form-wrapper"
        [ngClass]="{'error':(!businessUserForm.controls['name'].valid && businessUserForm.controls['name'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Ad
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" formControlName="name" maxlength="40" class="inputDefault"
                    placeholder="Lütfen adınızı resmi kimlik belgesindeki şekliyle ve Türkçe karakterler ile yazınız."
                    [(ngModel)]="this.businessUserApply.Name" upperCase>
                <span class="error-message d-none">Ad alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{ 'error': (!businessUserForm.controls['surname'].valid && businessUserForm.controls['surname'].touched) }">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Soyad
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" formControlName="surname" maxlength="30" class="inputDefault"
                    placeholder="Lütfen soyadınızı resmi kimlik belgesindeki şekliyle ve Türkçe karakterler ile yazınız."
                    [(ngModel)]="this.businessUserApply.Surname" upperCase>
                <span class="error-message d-none">Soyad alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!businessUserForm.controls['gsmNumber'].valid && businessUserForm.controls['gsmNumber'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            GSM No
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" mask="(000) 000 00 00" formControlName="gsmNumber" class="inputDefault"
                    placeholder="(5XX) XXX XX XX Telefon numaranıza onay kodu gönderilecektir."
                    [(ngModel)]="this.businessUserApply.CommunicationGsmNumber">

                <span class="error-message d-none">Telefon numarası alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!businessUserForm.controls['email'].valid && businessUserForm.controls['email'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Email
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required
                    formControlName="email" placeholder="" class="inputDefault" maxlength="50"
                    placeholder="Sözleşmeniz bu emaile gönderilecektir."
                    [(ngModel)]="this.businessUserApply.CommunicationEmail">
                <span class="error-message d-none">Email alanını doğru bir şekilde doldurunuz.</span>
            </div>
        </label>
    </div>
</form>