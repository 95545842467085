/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../base";
import * as i2 from "@angular/http";
import * as i3 from "./payment.component";
import * as i4 from "@angular/router";
import * as i5 from "../_models/PosModel";
import * as i6 from "../_models/DomesticMoneyTransferModel";
var styles_PaymentComponent = [];
var RenderType_PaymentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaymentComponent, data: {} });
export { RenderType_PaymentComponent as RenderType_PaymentComponent };
export function View_PaymentComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { form: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["form", 1]], null, 0, "form", [["action", ""], ["method", "post"], ["ngNoForm", ""]], null, null, null, null, null))], null, null); }
export function View_PaymentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-payment", [], null, null, null, View_PaymentComponent_0, RenderType_PaymentComponent)), i0.ɵprd(4608, null, i1.ApiRequest, i1.ApiRequest, []), i0.ɵprd(4608, null, i1.ApiResponse, i1.ApiResponse, []), i0.ɵprd(512, null, i1.ServiceBase, i1.ServiceBase, [i2.Http]), i0.ɵdid(4, 114688, null, 0, i3.PaymentComponent, [i1.ServiceBase, i4.Router, i2.Http, i5.PosModel, i6.DomesticMoneyTransferModel], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var PaymentComponentNgFactory = i0.ɵccf("app-payment", i3.PaymentComponent, View_PaymentComponent_Host_0, {}, {}, []);
export { PaymentComponentNgFactory as PaymentComponentNgFactory };
