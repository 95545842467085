<header-navbar [activeComponent]="'moneytransfer'"></header-navbar>

<top-slider [activeSlide]="'payment-fail'"></top-slider>


<div class="above-main-content">
    <div id="main-content" class="transfer-page">
        <div class="shadow d-none d-lg-block"></div>

    </div><!-- end of wrapper -->
</div>

<footer-template></footer-template>