import { Component, OnInit, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { DomesticMoneyTransferModel } from '../_models/DomesticMoneyTransferModel';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';
import { PosModel } from '../_models/PosModel';
import { Router, ActivatedRoute } from '@angular/router';
import * as jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    templateUrl: './payment-confirm.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class PaymentConfirmComponent implements OnInit {
    @ViewChild('receipt') el: ElementRef;
    sessionId: string;
    Email: string;
    imageReceipt: string;
    showReceipt: boolean = false;
    resultWebTransfer: any = [];
    receiptMailForm: FormGroup;
    isForVerification: boolean = false;

    constructor(private siteVariablesModel: SiteVariablesModel, private fb: FormBuilder, private moneyTransfer: DomesticMoneyTransferModel, private route: ActivatedRoute,
        public serviceBase: ServiceBase, public messageModel: MessageModel) {

        this.receiptMailForm = this.fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])]
        });

        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {
        this.isForVerification = false;


        this.route.queryParams.subscribe(params => {
            if (params['s'] != undefined)
                this.sessionId = params['s'];
        });

        if (this.sessionId != undefined) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "WebMoneyTransferController/SelectDomesticMoneyWebTransferDetailById");
            request.AddDataObjectToRequest("sessionId", this.sessionId);
            this.serviceBase.Execute(request).then(() => {

                this.showReceipt = true;
                this.resultWebTransfer = this.serviceBase.apiResponse.ResponseData.resultWebTransfer;
                this.isForVerification = this.serviceBase.apiResponse.ResponseData.resultWebTransfer[0].IsForVerification;

                if (this.resultWebTransfer[0].PaymentTypeName == "IBAN") {
                    window['dataLayer'].push({
                        'event': 'IBANPaymentSuccess'
                    });
                }
                else {
                    window['dataLayer'].push({
                        'event': 'PayPointPaymentSuccess'
                    });
                }

            });
        }

    }

    downloadReceiptPDFFile() {

        var data = document.getElementById('receipt');
        html2canvas(data).then(canvas => {
            // Few necessary setting options  
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
            var position = 15;
            pdf.addImage(contentDataURL, 'PNG', 15, position, imgWidth, imgHeight)
            pdf.save(this.resultWebTransfer[0].ReferenceNumber + ".pdf"); // Generated PDF   Change Name of pdf

        });

    }

    sendReceiptviaMail(formData, valid: boolean) {
        if (valid) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "WebMoneyTransferController/DomesticMoneyWebTransferSendReceipt");
            request.AddDataObjectToRequest("mail", formData.email);
            request.AddDataObjectToRequest("sessionId", this.sessionId);
            var data = document.getElementById('receipt');
            //get image base64 format
            html2canvas(data).then(canvas => {
                // Few necessary setting options  
                var imgWidth = 208;
                var pageHeight = 295;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                const contentDataURL = canvas.toDataURL('image/png')
                let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
                var position = 15;
                pdf.addImage(contentDataURL, 'PNG', 15, position, imgWidth, imgHeight)
                this.imageReceipt = pdf.output("datauristring");


            });

            setTimeout(() => {
                request.AddDataObjectToRequest("pdfFileBase64", this.imageReceipt);
                this.serviceBase.Execute(request).then(() => {
                    if (this.serviceBase.apiResponse.IsSuccessful) {
                        this.receiptMailForm.reset({
                            'email': ''
                        });
                        this.messageModel.showSuccess("Teşekkürler", "Dekont email adresinize gönderilmiştir.");
                    }

                    else {
                        this.messageModel.showError("Bir hata oluştu.", "Dekont mail adresinize gönderilemedi.Lütfen tekrar deneyiniz.");
                    }
                });
            }, 1000);

        }
        else {
            this.messageModel.showError("Bir hata oluştu.", "Lütfen mail adresinizi doğru bir formatta giriniz.");
        }

    }
}