import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { DomesticMoneyTransferModel } from '../_models/DomesticMoneyTransferModel';
import { PosModel } from '../_models/PosModel';
import { Router } from '@angular/router';
import { Http } from '@angular/http';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class PaymentComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  pageData: PosModel
  public PostUrl: string;

  constructor(private serviceBase: ServiceBase, private router: Router, private http: Http, private posModel: PosModel, public moneyTransfer: DomesticMoneyTransferModel) {
    this.pageData = posModel;
    this.PostUrl = "";
  }
  ngOnInit() {
    if (this.posModel.IsBusinessUser) {
      this.payTransactionForCollection();
    }
    else {
      this.payTransaction();
    }

    // else {
    //   if (this.moneyTransfer.TransactionSessionId == undefined) {
    //     this.router.navigate(['/money-transfer']);
    //   }
    //   else {
    //     this.payTransaction();
    //   }
    //}
  }

  payTransaction() {

    //#region 3dPay
    var request = new ApiRequest();
    request.AddDataObjectToRequest("MethodPath", "VirtualPosController/GetRedirectData");
    request.AddDataObjectToRequest("PosModel", this.posModel);
    request.AddDataObjectToRequest("sessionId", this.moneyTransfer.TransactionSessionId);

    this.serviceBase.Execute(request).then(() => {
      var RedirectDataKeyValuePair = this.serviceBase.apiResponse.ResponseData.RedirectDataKeyValuePair;
      this.PostUrl = this.serviceBase.apiResponse.ResponseData.PostUrl;
      this.form.nativeElement.action = this.PostUrl;

      for (let key in RedirectDataKeyValuePair) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = RedirectDataKeyValuePair[key];
        this.form.nativeElement.appendChild(input);
      }

      this.form.nativeElement.submit();

    });
    //#endregion
  }

  payTransactionForCollection() {
    var request = new ApiRequest();
    request.AddDataObjectToRequest(
      "MethodPath",
      "BusinessUserCollection/StartPaymentProcess"
    );
    request.AddDataObjectToRequest("Input", this.posModel);
    this.serviceBase.ExecuteForGonderAL(request, "Input").then(() => {
      var RedirectDataKeyValuePair = this.serviceBase.apiResponse.ResponseData.RedirectDataKeyValuePair;
      this.PostUrl = this.serviceBase.apiResponse.ResponseData.PostUrl;
      this.moneyTransfer.TransactionSessionId = this.serviceBase.apiResponse.ResponseData.TransactionSessionId;
      // console.log(this.PostUrl);

      this.form.nativeElement.action = this.PostUrl;

      for (let key in RedirectDataKeyValuePair) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = RedirectDataKeyValuePair[key];
        this.form.nativeElement.appendChild(input);
      }

      this.form.nativeElement.submit();
    });
  }
}
