import { Component, OnInit } from '@angular/core';
import * as Script from '../_shared-modules/scripts/script';

@Component({
    templateUrl: './aboutus.component.html',
})
export class AboutUsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        Script.changeAbout();
        Script.defaultAbout();
    }
}