import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Script from '../_shared-modules/scripts/script';

@Component({
    templateUrl: './paypoints.component.html',
})
export class PayPointsComponent implements OnInit {
    showBranch: boolean = true;
    constructor(private route: ActivatedRoute,) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {

            let sc = params['sc'];
            if (sc == "map-scroll") {
                setTimeout(() => {
                    Script.scrollTOElement('#map-scroll', -300, 0);
                    this.showBranch = false;
                }, 50);
            }
        });
    }
}