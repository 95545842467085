import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HeaderNavbarComponent } from './header-navbar.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [HeaderNavbarComponent],
    exports: [HeaderNavbarComponent, CommonModule, FormsModule],
    providers: []
})
export class HeaderNavbarModule { }