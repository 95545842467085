import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterTemplateComponent } from './footer-template.component';
import { MessageBoxModule } from '../message-box/message-box.module';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      MessageBoxModule
   ],
   declarations: [
      FooterTemplateComponent
   ],
   exports: [
      FooterTemplateComponent,
      CommonModule,
      FormsModule
   ],
   providers: []
})
export class FooterTemplateModule { }