import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UppercaseModule } from '../uppercase/uppercase.module';
import { MapSearchDetailComponent } from './mapsearch-detail.component';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UppercaseModule
  ],
  declarations: [MapSearchDetailComponent],
  exports: [MapSearchDetailComponent, CommonModule],
  providers: []
})
export class MapSearchDetailModule { }