import { Component, OnInit, Input } from '@angular/core';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';

@Component({
    selector: 'header-navbar',
    templateUrl: './header-navbar.component.html',
})
export class HeaderNavbarComponent implements OnInit {

    @Input() activeComponent: string;
    showCookie: boolean = false;

    constructor(public siteVariablesModel: SiteVariablesModel) {
        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {

        var visited = localStorage.getItem("visited");

        if (visited == "1") {
            this.showCookie = false;
        }
        else {
            localStorage.setItem("visited", "1");
            this.showCookie = true;
        }
    }

    onClose() {
        this.showCookie = false;
    }

}