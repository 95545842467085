export class BusinessUserApplyModel {
    public Name: string;
    public Surname: string;
    // public Tckn: string;
    public CommunicationGsmNumber: string;
    public CommunicationEmail: string;
    public LegalName: string;
    public TaxOffice: string;
    public TaxId: string;
    public ActivitySubject: string;
    public AddressDetails: string;
    public EstimateMonthlyCollection: number;
    public EstimateMonthlyShipping: number;
    public Description: string;
    public OtpCode: number;
    public Guid: string;
    public Loading: boolean = false;
}
