import { Component, OnInit } from '@angular/core';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';

@Component({
    templateUrl: './payment-fail.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class PaymentFailComponent implements OnInit {
    constructor(private siteVariablesModel: SiteVariablesModel,
    ) {

        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {

    }

}