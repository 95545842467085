import { Component, OnInit } from '@angular/core';
import { ApiRequest, ApiResponse, ServiceBase } from '../base';
import * as Script from '../_shared-modules/scripts/script';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';

@Component({
    templateUrl: './home.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})

export class HomeComponent implements OnInit {

    calculateDomesticForms: FormGroup;
    calculateInternationalForms: FormGroup;
    constructor(public formBuilder: FormBuilder, public serviceBase: ServiceBase, private route: ActivatedRoute, public siteVariablesModel: SiteVariablesModel, public messageModel: MessageModel) {
        this.calculateDomesticForms = this.formBuilder.group({
            'Amount': [null, Validators.compose([Validators.required])]
        });
        this.calculateInternationalForms = this.formBuilder.group({
            'Amount': [null, Validators.compose([Validators.required])],
            'Currency': ["", Validators.compose([Validators.required])],
            'Country': ["", Validators.compose([Validators.required])]
        });
        this.siteVariablesModel.setLanguage();
        this.siteVariablesModel.getClientBrowserDetail();
    }

    public TotalCommissionAmount: number;
    public TotalCommissionAmountInternational: number;
    public isShowDomestic: boolean;
    public isShowInternational: boolean;
    public currencyShortLabel: string;
    public countries: any[];
    ngOnInit() {

        Script.calculateFees(this.siteVariablesModel.language);

        var request = new ApiRequest();
        request.AddDataObjectToRequest("MethodPath", "CountryController/SelectCountryReceiveActive");
        this.serviceBase.Execute(request).then(() => {
            this.countries = this.serviceBase.apiResponse.ResponseData.Countries;
            setTimeout(() => {
                Script.selectPicker();
            }, 50);
        });

        this.route.queryParams.subscribe(params => {

            let sc = params['sc'];
            if (sc == "calculate-fees") {
                setTimeout(() => {
                    Script.scrollTOElement('#fees-calculator', -50, 0);
                }, 50);
            }
        });

        //Migros Overlay
        // var migrosOverlay = sessionStorage.getItem("mO");
        // if(migrosOverlay == undefined)
        // {
        //     sessionStorage.setItem("mO",  "1");
        //     $('#migros-modal').modal('show');
        // }

        this.route.queryParams.subscribe(params => {
          const isScrollToCalculator = params['isScrollToCalculator'];

          if (isScrollToCalculator === 'true') {
            this.scrollToFeeCalculator();
          }
        });

    }
    getCountryNameBySymbol(item) {
        if (this.siteVariablesModel.language == "EN")
            return item.Name;
        else
            return item.TurkishCountryName;
    }

    onCalculateDomestic(formData, valid: boolean) {
        if (valid) {

            Script.addProcessing($('#fees-calculator .local').parents('form'));
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "DomesticMoneyTransferController/SelectCommissionReferenceTableByInterval");
            request.AddDataObjectToRequest("BranchId", 0);
            request.AddDataObjectToRequest("Amount", formData.Amount);
            request.AddDataObjectToRequest("PaymentTypeId", 2);
            this.serviceBase.Execute(request).then(() => {
                Script.removeProcessing($('#fees-calculator .local').parents('form'));
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.TotalCommissionAmount = this.serviceBase.apiResponse.ResponseData.CommissionAmount + this.serviceBase.apiResponse.ResponseData.BSMVAmount;
                    this.isShowDomestic = true;
                }
                else {
                    this.isShowDomestic = false;
                    if (this.siteVariablesModel.language == "TR") {
                        this.messageModel.showError("Bir problem var", "Maksimum gönderim tutarı 7.000 TL'dir.");
                    }
                    else {
                        this.messageModel.showError("Ooops there's a problem", "Could not calculate fees for this amount");
                    }
                }
            });
        }

    }

    onCalculateInternational(formData, valid: boolean) {
        if (valid) {
            Script.addProcessing($('#fees-calculator .internal').parents('form'));
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "MoneyGramServiceController/MoneyGramCalculateWeb");
            request.AddDataObjectToRequest("Amount", formData.Amount);
            request.AddDataObjectToRequest("Currency", formData.Currency);
            request.AddDataObjectToRequest("Country", formData.Country);
            this.serviceBase.Execute(request).then(() => {
                Script.removeProcessing($('#fees-calculator .internal').parents('form'));
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.TotalCommissionAmountInternational = this.serviceBase.apiResponse.ResponseData.Commission;
                    switch (formData.Currency) {
                        case "TRY":
                            this.currencyShortLabel = "TL";
                            break;
                        case "USD":
                            this.currencyShortLabel = "USD";
                            break;
                        case "EUR":
                            this.currencyShortLabel = "EUR";
                            break;
                    }
                    this.isShowInternational = true;
                }
                else {
                    this.isShowInternational = false;
                    if (this.siteVariablesModel.language == "TR") {
                        this.messageModel.showError("Bir problem var", "Bu tutar için ücret hesaplanamadı");
                    }
                    else {
                        this.messageModel.showError("Ooops there's a problem", "Could not calculate fees for this amount");
                    }
                }
            });
        }

    }
    scrollToFeeCalculator() {
      const feesCalculator = document.getElementById('fees-calculator');
      feesCalculator.scrollIntoView({ behavior: 'instant' });
    }
}
