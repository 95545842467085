import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TopSliderComponent } from './top-slider.component';
import { RouterModule } from '@angular/router';
import { PaymentConfirmComponent } from 'src/app/PaymentConfirm/payment-confirm.component';
import { PaymentConfirmBusinessComponent } from 'src/app/PaymentConfirmBusiness/payment-confirm-business.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [TopSliderComponent],
    exports: [TopSliderComponent, CommonModule, FormsModule],
    providers: [PaymentConfirmComponent, PaymentConfirmBusinessComponent]
})
export class TopSliderModule { }
