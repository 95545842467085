import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ApiRequest, ApiResponse, ServiceBase } from 'src/app/base';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import * as Script from '../scripts/script';
import { } from 'google-maps';
import { ActivatedRoute } from '@angular/router';
declare var google;

@Component({
    selector: 'google-maps',
    templateUrl: './google-maps.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class GoogleMapsComponent implements OnInit {
    s: any;
    k: any;
    kMigros: string = "8513460001";
    showBranchCount: number = 10;
    public searchControl: FormControl;
    public latCurrent: number;
    public lngCurrent: number;
    public isShowBranch: boolean = false;
    lat: number = 41.109337;
    lng: number = 29.015669;
    zoom: number = 14;
    markers: any = [];
    @Input() isBranchDetail: boolean;
    @ViewChild('search')
    public searchElementRef: ElementRef;
    
    constructor(public serviceBase: ServiceBase, public branchModel: SiteVariablesModel, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private route: ActivatedRoute) {
        this.setCurrentPosition();
        this.branchModel.branches = [];
    }

    ngOnInit() {
        this.searchControl = new FormControl();
        this.route.queryParams.subscribe(params => {
            if (params['s'] != undefined)
                this.s = params['s'];
            if (params['k'] != undefined)
                this.k = params['k'];
        });
        /* for Migros params*/
        if (this.s != undefined && this.k == this.kMigros) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "BranchController/SelectBranchDetailbyBranchCode");
            request.AddDataObjectToRequest("BranchCode", this.s);
            this.serviceBase.Execute(request).then(() => {
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.branchModel.branches = this.serviceBase.apiResponse.ResponseData.ActiveBranchResults;
                    if (this.branchModel.branches != undefined && this.branchModel.branches.length > 0) {
                        this.mapsAPILoader.load().then(() => {
                            const center = new google.maps.LatLng(this.latCurrent, this.lngCurrent);
                            this.branchModel.branches.forEach(function (part, index) {
                                const markerLoc = new google.maps.LatLng(this[index].MapCoordinateX, this[index].MapCoordinateY);
                                const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center) / 1000;
                                this[index].Distance = distanceInKm;
                            },
                                this.branchModel.branches);
                            this.lat = this.branchModel.branches[0].MapCoordinateX;
                            this.lng = this.branchModel.branches[0].MapCoordinateY;
                            this.zoom = 12;
                        });
                        this.isShowBranch = true;
                    }
                    else {
                        alert("Harita yüklenemedi!");
                        this.selectAllBranches();

                    }
                }
                else {
                    this.selectAllBranches();
                    alert("Harita yüklenemedi!");
                }


            });
        }

        /* for Branch params*/
        else if (this.k != undefined && this.k != this.kMigros) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "BranchController/SelectBranchDetailbyAccountingBranchCode");
            request.AddDataObjectToRequest("AccountingBranchCode", this.k);
            this.serviceBase.Execute(request).then(() => {
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    if (this.serviceBase.apiResponse.ResponseData.ActiveBranchResults != undefined && this.serviceBase.apiResponse.ResponseData.ActiveBranchResults.length > 0) {
                        this.mapsAPILoader.load().then(() => {
                            const center = new google.maps.LatLng(this.latCurrent, this.lngCurrent);
                            this.serviceBase.apiResponse.ResponseData.ActiveBranchResults.forEach(function (part, index) {
                                const markerLoc = new google.maps.LatLng(this[index].MapCoordinateX, this[index].MapCoordinateY);
                                const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center) / 1000;
                                this[index].Distance = distanceInKm;
                            },
                                this.serviceBase.apiResponse.ResponseData.ActiveBranchResults);

                            this.branchModel.branches = this.serviceBase.apiResponse.ResponseData.ActiveBranchResults;
                            this.lat = this.branchModel.branches[0].MapCoordinateX;
                            this.lng = this.branchModel.branches[0].MapCoordinateY;
                            this.zoom = 12;
                        });
                        this.isShowBranch = true;
                    }
                    else {
                        this.selectAllBranches();
                        alert("Temsilci bulunamadı!");
                    }
                }
                else {
                    this.selectAllBranches();
                    alert("Temsilci bulunamadı!");
                }
            });
        }
        /* for All agents*/
        else {
            this.selectAllBranches();
        }

    }

    private setCurrentPosition() {
        if ('geolocation' in navigator)
            navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.latCurrent = this.lat;
                this.lngCurrent = this.lng;
                this.zoom = 14;
            });

    }

    selectAllBranches() {

        if (this.branchModel.branches == undefined || this.branchModel.branches.length < 1) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "BranchController/SelectActiveBranchDetail");
            this.serviceBase.Execute(request).then(() => {
                this.branchModel.branches = this.serviceBase.apiResponse.ResponseData.ActiveBranchResults;
                this.markers = this.branchModel.branches;
                this.mapsAPILoader.load().then(() => {
                    const center = new google.maps.LatLng(this.lat, this.lng);
                    this.branchModel.branches.forEach(function (part, index) {
                        const markerLoc = new google.maps.LatLng(this[index].MapCoordinateX, this[index].MapCoordinateY);
                        const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center) / 1000;
                        this[index].Distance = distanceInKm;
                    },
                        this.branchModel.branches);
                    this.branchModel.branches = this.transform(this.branchModel.branches, "Distance");
                });
            });
        }
        else
            this.markers = this.branchModel.branches;
    }

    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }

    onChange() {
        this.isBranchDetail = true;
        if (this.searchElementRef.nativeElement.value == undefined || this.searchElementRef.nativeElement.value == "") {
            this.zoom = 7;
            this.lat = 41.109337;
            this.lng = 29.015669;
        }
        else {
            this.branchModel.branches = this.transformSearch(this.markers, { LocationDistrictName: this.searchElementRef.nativeElement.value, LocationCityName: this.searchElementRef.nativeElement.value }, false);

            if (this.branchModel.branches != undefined && this.branchModel.branches.length > 0) {
                this.zoom = 7;
                this.lat = this.branchModel.branches[0].MapCoordinateX;
                this.lng = this.branchModel.branches[0].MapCoordinateY;
                this.showBranchCount = this.branchModel.branches.length;
                setTimeout(() => {
                    Script.scrollTOElement('#resultmapsearch', -40, 0);
                }, 0);
            }

        }
    }
    
    transformSearch(items: any, filter: any, defaultFilter: boolean): any {
        if (!filter) {
            return items;
        }
        if (!Array.isArray(items)) {
            return items;
        }

        if (filter && Array.isArray(items)) {
            let filterKeys = Object.keys(filter);

            if (defaultFilter) {
                return items.filter(item =>
                    filterKeys.reduce((x, keyName) =>
                        (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
            }
            else {
                return items.filter(item => {
                    return filterKeys.some((keyName) => {
                        return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
                    });
                });
            }
        }
    }
}