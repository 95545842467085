/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_shared-modules/header-navbar/header-navbar.component.ngfactory";
import * as i2 from "../_shared-modules/header-navbar/header-navbar.component";
import * as i3 from "../_models/SiteVariablesModel";
import * as i4 from "../_shared-modules/top-slider/top-slider.component.ngfactory";
import * as i5 from "../_shared-modules/top-slider/top-slider.component";
import * as i6 from "../PaymentConfirm/payment-confirm.component";
import * as i7 from "../PaymentConfirmBusiness/payment-confirm-business.component";
import * as i8 from "../_shared-modules/footer-template/footer-template.component.ngfactory";
import * as i9 from "../_shared-modules/footer-template/footer-template.component";
import * as i10 from "@angular/forms";
import * as i11 from "../base";
import * as i12 from "../_models/MessageModel";
import * as i13 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header-navbar", [], null, null, null, i1.View_HeaderNavbarComponent_0, i1.RenderType_HeaderNavbarComponent)), i0.ɵdid(1, 114688, null, 0, i2.HeaderNavbarComponent, [i3.SiteVariablesModel], { activeComponent: [0, "activeComponent"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "top-slider", [], null, null, null, i4.View_TopSliderComponent_0, i4.RenderType_TopSliderComponent)), i0.ɵdid(3, 114688, null, 0, i5.TopSliderComponent, [i3.SiteVariablesModel, i6.PaymentConfirmComponent, i7.PaymentConfirmBusinessComponent], { activeSlide: [0, "activeSlide"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "above-main-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "home-page"], ["id", "main-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "shadow d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "footer-template", [], null, null, null, i8.View_FooterTemplateComponent_0, i8.RenderType_FooterTemplateComponent)), i0.ɵdid(8, 114688, null, 0, i9.FooterTemplateComponent, [i10.FormBuilder, i11.ServiceBase, i12.MessageModel, i3.SiteVariablesModel], null, null)], function (_ck, _v) { var currVal_0 = "notfound"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "notfound"; _ck(_v, 3, 0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i13.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("ng-component", i13.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
