<header-navbar [activeComponent]="'businessuser'"></header-navbar>
<top-slider [activeSlide]="'businessuser'"></top-slider>

<!-- Content -->
<div class="above-main-content">
  <!-- Payment select box -->
  <div id="main-content" class="transfer-page">
    <!-- Content -->
    <div class="content-smaller">
      <div id="main-content" style="padding-top: 145px; padding-bottom: 140px;" class=" partner-item migros">
        <div class="row business" style="padding-top: 0;">
          <div class="col-md-12 text-center text-md-left pt-4 pt-md-0 text-center text-md-left pt-4 pt-md-0 serv-txt"
            [ngClass]="{'hide': siteVariablesModel.language == 'TR'}">
            <p class="sub-title">
              Faster receivables, easy payments
            </p>
            <hr>
            <p class="desc-blue">
              The fast and hassle free way to collect payments...
            </p>
            <p class="desc-black">
              Trying to collect your receivables may be a taunting task so let GönderAL handle it for you...
            </p>
            <div class="row items">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">1</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/system.png" />
                    <p class="text">
                      Upload a list of your receivables <br> to GönderAL’s system, attach your <br> invoices or account
                      statements <br> and click send.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">2</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/client.png" />
                    <p class="text">
                      GönderAL informs your clients, enables <br> them to pay with credit or bank cards <br> and remits
                      the collected funds to your <br> bank account. No delays, no hassles.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">3</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/message.png" />
                    <p class="text">
                      You will know when your <br> customer received the message, <br> made the payment and your bank
                      <br> account is credited.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="desc-black line23">
              Best of all, there’s no personal contact with clients to request payment and GönderAL keeps you informed
              at every stage of the transaction.
            </p>
            <p class="desc-blue">
              The quick and easy way for you to pay...
            </p>
            <p class="desc-black">
              Paying your vendors, employees and others is also very easy with GönderAL.
            </p>
            <div class="row items">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">1</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/card.png" />
                    <p class="text">
                      Upload a list of recipients, pay <br> with credit or bank card and click <br> send.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">2</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/migros.png" />
                    <p class="text">
                      Recipients receive cash at GönderAL <br> stores – including Migros.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">3</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/bank.png" />
                    <p class="text">
                      Or funds are transferred to their <br> bank accounts, regardless of <br> banking hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="desc-black line30">
              We serve individual businesses, SMEs, large corporations, non-profits and others. <br>
              Please switch to our site in Turkish language to sign up.
            </p>
          </div>
          <div class="col-md-12 text-center text-md-left pt-4 pt-md-0 text-center text-md-left pt-4 pt-md-0 serv-txt"
            [ngClass]="{'hide': siteVariablesModel.language != 'TR'}">
            <p class="sub-title">
              Hızlı tahsilat, kolay ödeme
            </p>
            <hr>
            <p class="desc-blue">
              Hızlı ve zahmetsiz tahsilat yöntemi...
            </p>
            <p class="desc-black">
              Alacak tahsil etmek sıkıcı bir iş. Bırakın GönderAL sizin için halletsin...
            </p>
            <div class="row items">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">1</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/system.png" />
                    <p class="text">
                      Alacak listenizi GönderAL sistemine yükleyin, hesap ekstrenizi veya faturanızı ekleyin ve
                      göndermek için tıklayın.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">2</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/client.png" />
                    <p class="text">
                      GönderAL müşterilerinizi bilgilendiriyor, kredi veya banka kartı ile ödeme yapmalarını sağlıyor ve
                      tahsil edilen parayı banka hesabınıza aktarıyor. Gecikme yok, uğraşmak yok.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">3</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/message.png" />
                    <p class="text">
                      Müşteriniz mesajı aldığında, ödemeyi yaptığında ve para banka hesabınıza aktarıldığında sizin
                      haberiniz oluyor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="desc-black line23">
              En güzeli, ödeme istemek için müşterilerinizi aramak zorunda değilsiniz ve işlemin her aşamasında GönderAL
              sizi bilgilendiriyor.
            </p>
            <p class="desc-blue">
              Hızlı ve kolay ödeme yapma yöntemi...
            </p>
            <p class="desc-black">
              Tedarikçilerinize, çalışanlarınıza ve diğerlerine GönderAL ile ödeme yapmak çok kolay.
            </p>
            <div class="row items">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">1</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/card.png" />
                    <p class="text">
                      Alıcıların listesini GönderAL sistemine yükleyin, kredi veya banka kartı ile ödeyin ve göndermek
                      için tıklayın.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">2</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/migros.png" />
                    <p class="text">
                      Alıcılar GönderAL ödeme noktalarından alsın – Migros dahil.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-2 item">
                    <svg viewBox="0 0 40 40" class="ellipse">
                      <ellipse rx="20" ry="20" cx="20" cy="20"></ellipse>
                    </svg>
                    <div class="ellipse">3</div>
                    <svg viewBox="-1.5 -1.5 3 111" class="line">
                      <path d="M 0 0 L 0 108"></path>
                    </svg>
                  </div>
                  <div class="col-md-10 content">
                    <img src="../../assets/img/icons/bank.png" />
                    <p class="text">
                      Veya EFT saatlerine bakılmaksızın alıcıların banka hesabına aktarılsın.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="desc-black line30">
              Bireysel işletmelere, KOBİlere, kurumsal şirketlere, kar amacı gütmeyen kuruluşlara ve diğerlerine hizmet
              sunuyoruz.<br>
              Başlamak için aşağıdaki formu doldurun.
            </p>
            <hr>
          </div>
          <div class="col-md-12" style="margin-top: 50px;" [ngClass]="{'hide': siteVariablesModel.language == 'TR'}">
            <div class="button" style="width: 25%;">
              <a href="{{siteVariablesModel.turkishHref}}/businessuser" class="buttonDefault button-red size26">Switch
                to Turkish</a>
            </div>
          </div>
        </div>
      </div><!-- End Main Content -->
    </div>
    <!-- Bank Account -->
    <div class="content payment-item" [ngClass]="{'hide': siteVariablesModel.language != 'TR'}">
      <!-- Bills Results -->
      <form action="#" method="post">
        <div class="content-smaller">
          <!-- START Your Details -->
          <div class="your-details mx-auto">
            <div class="your-details-wrapper">
              <div class="your-details-title border-bottom-grey">
                <h5 class="text-center text-lg-left position-relative">Kullanıcı Bilgileri</h5>
              </div>
              <div class="form-block expand-area">
                <businessuser-userinfo></businessuser-userinfo>
              </div>
            </div>
          </div>
          <!-- END Your Details -->

          <!-- START Block -->
          <div class="recipient-block mx-auto">
            <div class="recipient-block-wrapper">
              <div class="recipient-block-title border-bottom-grey">
                <h5 class="text-center text-lg-left position-relative">Şirket / Kurum Bilgileri</h5>
              </div>
              <div class="form-block expand-area" style="margin-top:5px;">
                <businessuser-companyinfo> </businessuser-companyinfo>
              </div>
            </div>
          </div>
          <!-- END Block -->

          <form action="#" [formGroup]="otpCodeForms" [ngClass]="{'hide': otpCodeComponentShow}">
            <div class="form-wrapper" style="margin-top: 48px;">
              <label
                class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between"
                style="justify-content: center !important;">

                <span style="font-weight: bolder;font-size: 20px;">Doğrulama Kodu</span>

                <div class="error-wrapper" style="width: 20%; margin-left: 34px;">
                  <input type="number" class="buttonDefault" style="color: black;" id="otpCodeInput"
                    formControlName="OtpCodeValue" [(ngModel)]="this.businessUserApply.OtpCode" upperCase>
                  <span class="error-message d-none">Lütfen zorunlu alanları doldurunuz.</span>
                </div>

                <div class="error-wrapper" style="margin-left: 34px;">
                  <input type="submit" value="Doğrula" id="sendOtpButtonId" class="buttonDefault button-blue"
                    (click)="OtpCodeVerifyGsmNumber(otpCodeForms.value,otpCodeForms.valid)">
                  <span class="error-message d-none">Lütfen zorunlu alanları doldurunuz.</span>
                </div>
              </label>
            </div>
          </form>

          <div class="form-wrapper" style="margin-top: 48px;" [ngClass]="{'hide': otpCodeComponentShow}">
            <label class="text-blue d-flex flex-wrap align-items-center justify-content-center">
              <span style="font-weight: bolder;font-size: 20px;">Süre : </span>
              <div class="error-wrapper text-blue" style="width: 115px;">
                <input type="number" disabled class="buttonDefault" id="otpCodeTimer"
                  style="color:#00BAFF; border:none; font-size: 22px;" />
              </div>
              <div class="error-wrapper">
                <span style="font-weight: bolder;font-size: 22px;"> Sn </span>
              </div>
            </label>
          </div>

          <!-- Submit Transfer -->
          <div class="submit-transfer mx-auto" [ngClass]="{'hide': otpCodeAgainComponentShow}">
            <div class="submit-transfer-wrapper">
              <div class="form-block expand-area">
                <div class="button-block text-center text-lg-center mt-4 mt-lg-0">
                  <div class="input-wrapper d-inline-flex">
                    <input type="submit" value="Tekrar Kod Gönder" id="sendOtpCodeButtonId"
                      class="buttonDefault button-red size26 confirm-form" (click)="businessUserApplyRequestOnClick()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END Submit Transfer -->

          <div class="img-rounded" style="position: fixed;  top:30%; left:48%; z-index: 9999;"
            *ngIf="businessUserApply.Loading">
            <img src="../../assets/gif/loading.gif" [ngClass]="{'disabled':  businessUserApply.Loading }" />
          </div>

          <!-- Submit Transfer -->
          <div class="submit-transfer mx-auto" [ngClass]="{'hide': sendButtonComponentShow}">
            <div class="submit-transfer-wrapper">
              <div class="form-block expand-area">
                <div class="button-block text-center text-lg-center mt-4 mt-lg-0">
                  <div class="input-wrapper d-inline-flex">
                    <input type="submit" value="İleri" id="sendOtpCodeButtonId"
                      class="buttonDefault button-red size26 confirm-form" (click)="businessUserApplyRequestOnClick()">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<footer-template></footer-template>

<div class="modal fade" id="transfer-success-modal" tabindex="-1" role="dialog" aria-labelledby="Е7С"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Teşekkürler. Başvurunuz alındı. En kısa sürede sizin ile iletişime geçeceğiz.</h3>
      </div>
      <div class="modal-footer d-flex flex-column align-items-start justify-content-start">
        <p class="text-dark-green text-19">A transaction receipt has be sent to yourself and the recipient by
          SMS text message</p>
        <input type="button" value="Close and return to Transfers"
          class="text-26 buttonDefault button-red confirm-success-button" data-dismiss="modal">
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="moneyTransferBank" name="moneyTransferBank" tabindex="-1" role="dialog"
  aria-labelledby="Е7С" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h3></h3>
      </div>

      <div class="modal-body d-flex justify-content-between justify-content-md-start align-items-center text-19">

        <div class="recipient-info-block text-main-blue">

          <iframe width="100%" height="100%" id="frame" style="min-width: 50vw; min-height: 26vw" name="frame"
            frameBorder="5" method="post">

          </iframe>
          <form #form ngNoForm action="" method="post" target="frame">

          </form>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="request-warning-expanded" tabindex="-1" role="dialog" aria-labelledby="Е7С"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h3></h3>
        <p><b>Lütfen bir önceki bölümü tam olarak doldurunuz.</b></p>
      </div>

    </div>
  </div>
</div>