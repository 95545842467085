<header-navbar [activeComponent]="'contact'"></header-navbar>
<top-slider [activeSlide]="'contact'"></top-slider>

<div class="above-main-content">
    <div class="content"></div>
    <div id="main-content" class="contact-page">
        <div class="shadow d-none d-lg-block"></div>
        <div class="content">
            <!-- Form -->
            <div id="ask-a-question" class="blue-form d-flex flex-column ml-auto">
                <h4 i18n="@@ask-a-question" class="flex-1">Ask a question</h4>
                <form [formGroup]="askQuestionForm"
                    (ngSubmit)="addQuestion(askQuestionForm.value,askQuestionForm.valid)">
                    <div class="row mr-0 ml-0 col-12 paddingZero">
                        <div class="row col-12 mr-0 ml-0 paddingZero">
                            <div class="col-lg-6 pl-lg-0 pr-lg-2">
                                <div class="form-wrapper">
                                    <input type="text" formControlName="name" name="text" i18n-placeholder
                                        placeholder="Name" maxlength="100">
                                    <span i18n="@@error-enter-name" class="error-message">Please enter your name.</span>
                                </div>
                            </div>
                            <div class="col-lg-6 pl-lg-1 pr-lg-0">
                                <div class="form-wrapper">
                                    <input type="email" formControlName="email" name="text" i18n-placeholder
                                        placeholder="Email" maxlength="50">
                                    <span i18n="@@error-enter-email" class="error-message">Please enter your email.</span>
                                </div>
                            </div>
                        </div>
                        <div class="row col-12 mr-0 ml-0 paddingZero">
                            <div class="col-lg-6 pl-lg-0 pr-lg-2">
                                <div class="form-wrapper">
                                    <input type="text" formControlName="subject" name="text" i18n-placeholder
                                        placeholder="Subject" maxlength="100">
                                    <span i18n="@@error-enter-subject" class="error-message">Please enter subject.</span>
                                </div>
                            </div>
                            <div class="col-lg-6 pl-lg-1 pr-lg-0">
                                <div class="form-wrapper">
                                    <input type="text" formControlName="gsmnumber" mask="(000) 000 00 00" name="text"
                                        i18n-placeholder placeholder="GSM Number">
                                    <span i18n="@@error-enter-gsmnumber" class="error-message">Please enter your gsm number.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pl-lg-0 pr-lg-0">
                            <div class="form-wrapper">
                                <textarea formControlName="message" class="w-100" i18n-placeholder placeholder="Message"
                                    maxlength="4000"></textarea>
                            </div>
                        </div>
                        <div class="form-wrapper submit-wrap">
                            <span class="calculate-button">
                                <input type="submit" i18n-value value="Submit"
                                    class="buttonDefault button-red size26 submit">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                        <div class="row col-12 mr-0 ml-0 paddingZero" *ngIf="isCaptchaShow">
                          <div class="col-lg-6 pl-lg-0 pr-lg-2">
                            <div class="form-wrapper" style="padding-top: 20px;">
                              <re-captcha class="re-captcha" (resolved)="resolved($event)" id="recaptcha">
                              </re-captcha>
                            </div>
                          </div>
                        </div>
                    </div>
                </form>
            </div>
            <!-- end Form -->
        </div>
    </div>
</div>
<footer-template></footer-template>
