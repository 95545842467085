import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiRequest, ServiceBase } from 'src/app/base';
import { MessageModel } from 'src/app/_models/MessageModel';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';

@Component({
    selector: 'footer-template',
    templateUrl: './footer-template.component.html',
})
export class FooterTemplateComponent implements OnInit {

    ftSubscribeForm: FormGroup;

    constructor(private fb: FormBuilder, public serviceBase: ServiceBase, public messageModel: MessageModel, public siteVariablesModel: SiteVariablesModel) {
        this.ftSubscribeForm = this.fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])]
        });
        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {

    }

    addSubscriber(formData, valid: boolean) {
        if (valid) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "SubscriberController/InsertSubscriber");
            request.AddDataObjectToRequest("Email", formData.email);
            this.serviceBase.Execute(request).then(() => {
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.ftSubscribeForm.reset({
                        'email': ''
                    });

                    if (this.siteVariablesModel.language == "TR") {
                        this.messageModel.showSuccess("Teşekkürler", "Artık bizden haberleri alacaksınız");
                    }
                    else {
                        this.messageModel.showSuccess("Thank you", "You will receive news from us");
                    }

                } else {
                    if (this.siteVariablesModel.language == "TR") {
                        this.messageModel.showError("Bir problem var", "Sizi listeye eklerken hata oluştu, lütfen tekrar deneyiniz");
                    }
                    else {
                        this.messageModel.showError("Ooops there's a problem", "An error occurred when adding you, please try again");
                    }
                }

            });
        }
    }

}