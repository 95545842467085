import { Injectable } from "@angular/core";
import { Http, Headers } from '@angular/http';
import { environment } from './../environments/environment';

let apiBaseURL: string = window.location.protocol + "//" + window.location.hostname + "/api";
let apiPrefix: string = "/api/";
let aTrpvbkd: string = "a1?Ro7vMex3k";

//let apiBaseURLForGonderlAl = environment.apiv2Url;
//let apiBaseURLForGonderlAl = "http://localhost:1164" + apiPrefix;
let apiBaseURLForGonderlAl = window.location.protocol + "//" + window.location.hostname + "/apiv2" + apiPrefix;

@Injectable()
export class ApiRequest {
    public AddDataObjectToRequest(name: string, objectToAdd: any) {
        this[name] = objectToAdd;
    }
}

@Injectable()
export class ApiResponse {
    public IsSuccessful: boolean;
    public ErrorCode: string;
    public ErrorMessage: string;
    public ResponseData: any;
    public access_token: string;
    public userName: string;
    public error_description: string;
    constructor() {
        this.ErrorMessage = null;
        this.IsSuccessful = null;
        this.ResponseData = null;
        this.access_token = null;
        this.userName = null;
    }

}

@Injectable()
export class ServiceBase {
    public apiResponse: ApiResponse;
    public apiRequest: ApiRequest;
    private apiBaseURL = apiBaseURL;

    constructor(private http: Http) {
        this.apiResponse = new ApiResponse();
        this.apiRequest = new ApiRequest();
    }

    Execute(request: ApiRequest) {

        this.apiResponse.IsSuccessful = false;
        this.apiRequest = request;

        let promise = new Promise((resolve, reject) => {
            let apiURL = this.apiBaseURL + apiPrefix + this.apiRequest["MethodPath"];
            this.apiRequest['WebAppKey'] = aTrpvbkd;
            var objectToSend = JSON.stringify(this.apiRequest);
            const headersContent = new Headers({
                'Content-Type': 'application/json'
            });
            let that = this;

            this.http.post(apiURL, objectToSend, { headers: headersContent })
                .toPromise()
                .then(res => {
                    that.apiResponse = res.json();
                    resolve();
                },
                    msg => {
                        reject(msg);
                    });
        });
        return promise;
    }

    ExecuteForGonderAL(request: ApiRequest, methodModelName) {

        this.apiResponse.IsSuccessful = false;
        this.apiRequest = request;

        let promise = new Promise((resolve, reject) => {
            let apiURL = apiBaseURLForGonderlAl + this.apiRequest["MethodPath"];
            var objectToSend = JSON.stringify(this.apiRequest[methodModelName]);
            const headersContent = new Headers({
                'Content-Type': 'application/json'
            });
            let that = this;

            this.http.post(apiURL, objectToSend, { headers: headersContent })
                .toPromise()
                .then(res => {
                    that.apiResponse = res.json();
                    resolve();
                },
                    msg => {
                        reject(msg);
                    });
        });
        return promise;
    }

}
