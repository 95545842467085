import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as Script from '../_shared-modules/scripts/script';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceBase, ApiRequest, ApiResponse } from '../base';
import { DomesticMoneyTransferModel } from '../_models/DomesticMoneyTransferModel';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './verify.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class VerifyComponent implements OnInit {
    verifyForm: FormGroup;
    verifyReference: string;
    verifyReferenceValid: boolean;
    isVerified: boolean;

    constructor(public formBuilder: FormBuilder, public serviceBase: ServiceBase, public moneyTransfer: DomesticMoneyTransferModel, public siteVariablesModel: SiteVariablesModel,
        private messageModel: MessageModel, private router: Router, private activatedRoute: ActivatedRoute) {

        this.verifyForm = this.formBuilder.group({
            'amount1': [null, Validators.compose([Validators.required])],
            'amount2': [null, Validators.compose([Validators.required])],
            'amount3': [null, Validators.compose([Validators.required])]
        });
        this.siteVariablesModel.setLanguage();
        this.verifyReferenceValid = false;
    }

    ngOnInit() {

        this.verifyReference = undefined;
        this.verifyReferenceValid = false;
        this.isVerified = false;
        this.verifyForm.reset();

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['ref'] != undefined)
                this.verifyReference = params['ref'];
        });

        var request = new ApiRequest();
        request.AddDataObjectToRequest("MethodPath", "WebCustomerVerificationController/SelectVerificationReference");
        request.AddDataObjectToRequest("VerificationReference", this.verifyReference);
        this.serviceBase.Execute(request).then(() => {
            if (!this.serviceBase.apiResponse.IsSuccessful) {
                this.showError(this.serviceBase.apiResponse.ErrorCode, this.serviceBase.apiResponse.ErrorMessage);
            }
            else {
                this.verifyReferenceValid = true;
            }
        });

    }

    goToHome() {
        this.router.navigate(['/']);
    }

    goToTransferPage() {
        //this.router.navigate(['/money-transfer']);
    }

    verify(formData, valid: boolean) {

        if (valid) {
            this.isVerified = false;
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "WebCustomerVerificationController/Verify");
            request.AddDataObjectToRequest("VerificationReference", this.verifyReference);
            request.AddDataObjectToRequest("Amount1", formData.amount1);
            request.AddDataObjectToRequest("Amount2", formData.amount2);
            request.AddDataObjectToRequest("Amount3", formData.amount3);
            this.serviceBase.Execute(request).then(() => {
                if (!this.serviceBase.apiResponse.IsSuccessful) {
                    this.showError(this.serviceBase.apiResponse.ErrorCode, this.serviceBase.apiResponse.ErrorMessage);
                }
                else {
                    this.isVerified = true;
                    this.messageModel.showSuccess("Teşekkürler", "Doğrulama işleminiz başarılı bir şekilde tamamlanmıştır.");
                }

            });

        }
    }

    showError(errorCode: string, errorMessage: string) {
        if (errorCode == "WEB002") {
            this.router.navigate(['/not-found']);
        }
        else if (errorCode == "WEB003") {
            this.messageModel.showError("Bir Problem Var", "Üst üste birçok kez tutarları hatalı girdiğiniz için doğrulamanız başarısız olmuştur, yardım için lütfen çağrı merkezimizi arayınız: +90 850 242 4442");
        }
        else if (errorCode == "WEB004") {
            this.verifyReferenceValid = true;
            this.isVerified = true;
        } else if (errorCode == "WEB005") {
            this.messageModel.showError("Hatalı Bilgi", "Lütfen banka hesabınıza gönderdiğimiz 3 tutarı banka hesabınızdan kontrol ederek buraya girin.");
        }
        else {
            this.messageModel.showError("Bir Problem Var", errorMessage);
        }

    }


}