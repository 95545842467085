import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsComponent } from './google-maps.component';
import { AgmCoreModule } from '@agm/core';
import { OrderPipe } from 'src/app/_pipes/OrderPipe/orderpipe';
import { UppercaseModule } from '../uppercase/uppercase.module';

@NgModule({
  imports: [CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBYHHo_ZBjUVJ8AUK-fEAiXNju1Z9R6YAc',
      libraries: ['geometry', 'places']
    }),
    FormsModule,
    ReactiveFormsModule,
    UppercaseModule
  ],
  declarations: [GoogleMapsComponent, OrderPipe],
  exports: [GoogleMapsComponent, CommonModule],
  providers: []
})
export class GoogleMapsModule { }