export function slickFunction() {

    $('.top-slider .slides:not(.paypoints-slides)').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        speed: 800,
        cssEase: 'linear',
        appendArrows: $('.arrow-wrapper'),
        prevArrow: '<div class="arrow left"><img src="/assets/img/arrow-slider.png"></div>',
        nextArrow: '<div class="arrow right"><img src="/assets/img/arrow-slider.png"></div>',
      });

      $('.top-slider .paypoints-slides').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 1500,
          pauseOnHover: false,
          cssEase: 'linear',
          appendArrows: $('.arrow-wrapper'),
          prevArrow: '<div class="arrow left"><img src="/assets/img/arrow-slider.png"></div>',
          nextArrow: '<div class="arrow right"><img src="/assets/img/arrow-slider.png"></div>',
      });
}

// export function setCurrentSlide(activeSlide)
// {
//     var slideNumber = 0;

//     switch(activeSlide) {
//         case "home":
//             slideNumber = 0;
//             break;
//         case "mobileapp":
//             slideNumber = 1;
//             break;
//         case "contact":
//             slideNumber = 2;
//             break;
//         case "partners":
//             slideNumber = 3;
//             break;
//         case "paypoints":
//             slideNumber = 4;
//             break;
//         case "aboutus":
//             slideNumber = 5;
//             break;
//         case "documents":
//             slideNumber = 5;
//             break;
//         case "services":
//             slideNumber = 5;
//             break;
//         default:
//         slideNumber = 0;
//       }


//     try {
//         $('.top-slider .slides').slick("slickGoTo", slideNumber, true);
//     }
//     catch(error) {
//         setTimeout(setCurrentSlide, 100);
//         return;
//     }

//     $('.top-slider .slides').slick("slickGoTo", slideNumber, true);

// }

export function scrollTOElement(element, offsetParam, speedParam) {

    const toElement = $(element);
    const focusElement = $(element);
    const offset = offsetParam * 1 || 200;
    const speed = speedParam * 1 || 500;
    $('html, body').animate({
        scrollTop: toElement.offset().top + offset
    }, speed);
    if (focusElement) {
        $(focusElement).focus();
    }

}

function validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test($email);
}

function validateFields(fields, calback) {

    var error = true;

    $(fields).each(function () {

        var val = $(this).val();
        let empty = false;

        if ($(this).is('select')) {
            val = $(this).find('option:selected').val();
        }

        if ($(this).is('[type="checkbox"]')) {

            val = $(this).is(':checked');

        }

        if (val == '') {

            $(this).parents('.form-wrapper, .form-group').addClass('error');


            empty = true;
            error = false;

        } else {


            $(this).parents('.form-wrapper, .form-group').removeClass('error')

        }

        if (calback) calback($(this), empty);

    });


    return error;

}



export function validatePartnerRequestFields() {

    $('#partner-request .submit').on('click', function (e) {

        if (!validateFields($(this).parents('form').find('input, select'))) {
            e.preventDefault();
        }

    })

}

export function addProcessing(form) {
    var cButtonWrapper = form.find('.calculate-button');
    var cButtonSubmit = form.find('.calculate-button input[type="submit"]');
    var cResult = form.find('.calculate-result');
    cButtonWrapper.addClass('processing');
    cButtonSubmit.attr('disabled', true);
    cResult.removeClass('d-block').addClass('d-none');
}

export function removeProcessing(form) {
    var cButtonWrapper = form.find('.calculate-button');
    var cButtonSubmit = form.find('.calculate-button input[type="submit"]');
    var cResult = form.find('.calculate-result');
    cButtonSubmit.attr('disabled', false);
    cButtonWrapper.removeClass('processing');
    cResult.removeClass('d-none').addClass('d-block');
}

export function validateAskAQuestionFields() {
    $('#ask-a-question .submit').on('click', function (e) {

        if (!validateFields($(this).parents('form').find('input, select'))) {
            e.preventDefault();
        }

    })

}

export function calculateFees(language) {
    //$('.selectpicker').selectpicker();

    //Calculate Select
    $('#fees-calculator .switcher').click(function () {
        let formName = '#' + $(this).attr('data-form');
        $('#fees-calculator .switcher, #fees-calculator .forms').removeClass('active');
        $(this).addClass('active');

        $(formName).addClass('active');
    });

    $('#fees-calculator .internal').on('click', function (e) {

        e.preventDefault();
        if (validateFields($(this).parents('form').find('input, select'))) {
            // $( this ).parents( 'form' ).find( '.calculate-result' ).removeClass( 'd-none' ).addClass( 'd-block' );
            if (language == "TR")
                $(this).val('Yeniden Hesapla');
            else
                $(this).val('Recalculate');
        }
    });

    $('#fees-calculator .local').on('click', function (e) {

        e.preventDefault();
        if (validateFields($(this).parents('form').find('input, select'))) {

            // $( this ).parents( 'form' ).find( '.calculate-result' ).removeClass( 'd-none' ).addClass( 'd-block' );
            if (language == "TR")
                $(this).val('Yeniden Hesapla');
            else
                $(this).val('Recalculate');
        }

    })
}

export function changeAbout() {
    $('.omt-button a').on('click', function (e) {

        e.preventDefault();

        var omtItem = $(this).parents('.omt-item').eq(0);
        var bioId = omtItem.data('bio-id');
        var activeBio = $('.omt-bio-item.active');

        if (activeBio.data('bio-id') != bioId) {

            if ($(window).width() > 576) {

                $('.omt-item.active').removeClass('active');
                $('.omt-bio-item-mobile.active').removeClass('active');

                $('.omt-bio-item.active').removeClass('active').fadeOut(300, function () {
                    var omtItemFiltered = $('.omt-item').filter('[data-bio-id="' + bioId + '"]');
                    omtItemFiltered.addClass('active');
                    omtItemFiltered.find('.omt-bio-item-mobile').addClass('active');
                    $('.omt-bio-item').filter('[data-bio-id="' + bioId + '"]').fadeIn(1000).addClass('active');
                });

            } else {

                $('.omt-item.active').removeClass('active');
                $('.omt-bio-item.active').removeClass('active');

                $('.omt-bio-item-mobile.active').removeClass('active').hide(100, function () {
                    var omtItemFilteredBioItem = $('.omt-bio-item').filter('[data-bio-id="' + bioId + '"]');
                    omtItemFilteredBioItem.addClass('active');
                    omtItem.addClass('active');

                    omtItem.find('.omt-bio-item-mobile').show(300).addClass('active');
                });
            }
        }
    });
}
export function defaultAbout() {

    if ($(window).width() > 576) {

        $('.omt-item.active').removeClass('active');
        $('.omt-bio-item-mobile.active').removeClass('active');

        $('.omt-bio-item.active').removeClass('active').fadeOut(300, function () {
            var omtItemFiltered = $('.omt-item').filter('[data-bio-id="' + 1 + '"]');
            omtItemFiltered.addClass('active');
            omtItemFiltered.find('.omt-bio-item-mobile').addClass('active');
            $('.omt-bio-item').filter('[data-bio-id="' + 1 + '"]').fadeIn(1000).addClass('active');
        });

    } else {

        $('.omt-item.active').removeClass('active');
        $('.omt-bio-item.active').removeClass('active');

        $('.omt-bio-item-mobile.active').removeClass('active').hide(100, function () {
            var omtItemFilteredBioItem = $('.omt-bio-item').filter('[data-bio-id="' + 1 + '"]');
            omtItemFilteredBioItem.addClass('active');
            omtItem.addClass('active');

            omtItem.find('.omt-bio-item-mobile').show(300).addClass('active');
        });
    }
}

export function agentLogin() {
    $('#agentLogin').click(function (e) {
        e.preventDefault('languageLogin');
        if ($('#login').hasClass('d-none'))
            $('#login').removeClass('d-none');
        else
            $('.languageLogin form').addClass('d-none');
    });
    //Switcher between Login/Pass reset forms
    $('.forget-password').click(function () {
        var form = $(this).find('a').attr('data-form');
        $('.languageLogin form').addClass('d-none')

        $('#' + form).removeClass('d-none');
    });
    //Cookie Accept
    $('.cookieAccept').click(function (e) {
        e.preventDefault();
        $('.cookiePolice').addClass('d-none');
    });

}

// Transfer Page

export function moneyTransfer() {



    //#region SelectPaymentType
    $('.paymentSelect, .paymentSelect-blue').on('change', function () {

        let id = $(this).find('option:selected').val();

        $('.payment-item').hide();

        if ($('#' + id).length) {

            $('#' + id).fadeIn(300);

            if ($(this).hasClass('paymentSelect-blue')) {

                let text = $(".paymentSelect select option[value='" + id + "']").text();

                $('.paymentSelect .filter-option-inner-inner').text(text);
                $('.paymentSelect select').val(id);

            }

        }

    })
    //#endregion
    // Validating
    // $(document).on('keyup change', '.validate-your-details', function () {

    //     validateFields($(this).parents('.your-details').find('input, select'));

    //     $(this).parents('.your-details').find('.form-wrapper, .form-group').addClass('validate-your-details');

    // })



    $('.validate-transfer-money').on('click', function () {

        let validateResult = validateFields($(this).parents('.transfer-money-block').find('input, select'));

        if (validateResult) {

            $('.bills-results').fadeIn(300);

        }

    })


    //#endregion
    //#region Validate CARD DETAİLS    
    // $(document).on('keyup change', '.validate-bank-card', function () {

    //     let validateResult = validateFields($(this).parents('.card-details').find('input, select'));
    //     // console.log($( this ).parents( '.card-details' ).find( 'input, select' ))
    //     $(this).parents('.card-details').find('.form-wrapper, .form-group').addClass('validate-bank-card');


    //     if (validateResult) {

    //         $(this).parents('.content').find('.submit-transfer .expand-block').addClass('expanded granted')
    //         $(this).parents('.content').find('.submit-transfer .expand-area').fadeIn(300)

    //     }

    // })
    //#endregion


    $(document).on('keyup change', '.validate-recipient-block', function () {

        let validateResult = validateFields($(this).parents('.recipient-block').find('input, select'));

        $(this).parents('.recipient-block').find('.form-wrapper, .form-group').addClass('validate-recipient-block');

        if (validateResult) {
            $(this).parents('.recipient-block').find('.map-search-result').fadeIn(300);
            $(this).parents('.content').find('.your-details .expand-block').addClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').fadeIn(300)

        }
        else {

            $(this).parents('.content').find('.your-details .expand-block').removeClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').hide(300)
        }

    })


    $('.transfer-page form').on('submit', function (e) {

        let validateResult = validateFields($(this).find('input, select, checkbox'), function (item, empty) {

            if (empty && $(item).parents('.expand-block').length) {

                $(item).parents('.expand-block').addClass('expanded granted');
                $(item).parents('.expand-area').fadeIn(300);
            }

        });
        // console.log()
        if (!validateResult) {

            scrollToError();
            return false;
        }

    })

    $('.confirm-form').on('click', function (e) {

        let validateResult = validateFields($(this).parents('form').find('input, select, checkbox'), function (item, empty) {

            if (empty && $(item).parents('.expand-block').length) {

                $(item).parents('.expand-block').addClass('expanded granted');
                $(item).parents('.expand-area').fadeIn(300);
            }

        });
        if (!validateResult) {

            scrollToError();
            return false;

        }

        $('#transfer-confirm-modal').modal();


    })

    $(document).on('click', '.validate-transfer-find', function () {

        let validateResult = validateFields($(this).parents('.recipient-block').find('input:not([type="checkbox"]), select'));

        // $( this ).parents( '.recipient-block' ).find( '.form-wrapper, .form-group' ).addClass( 'validate-transfer-find' );

        if (validateResult) {

            $(this).parents('.content').find('.map-search-result').fadeIn(300);
            $(this).parents('.content').find('.your-details .expand-block').addClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').fadeIn(300)

        }


    })
    $(document).on('click', '.validate-transfer-bankccount', function () {

        let validateResult = validateFields($(this).parents('.recipient-block').find('input,select'));

        // $( this ).parents( '.recipient-block' ).find( '.form-wrapper, .form-group' ).addClass( 'validate-transfer-find' );

        if (validateResult) {

            $(this).parents('.content').find('.map-search-result').fadeIn(300);
            $(this).parents('.content').find('.your-details .expand-block').addClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').fadeIn(300)

        }


    })



    // END Validating


    $(document).on('click', '.expand-button', function () {


        if ($(this).parents('.expand-block').hasClass('granted')) {

            if ($(this).parents('.expand-block').hasClass('expanded')) {

                $(this).parents('.expand-block').removeClass('expanded');
                $(this).parents('.expand-block').find('.expand-area').hide(300)

            } else {

                $(this).parents('.expand-block').addClass('expanded');
                $(this).parents('.expand-block').find('.expand-area').fadeIn(300)

            }

        }
        else {
            $('#request-warning-expanded').modal('show');
        }

    })

    $(document).on('click', '.tick', function () {

        let parentBillItem = $(this).parents('.bill-item');

        if (parentBillItem.hasClass('selected')) {

            parentBillItem.removeClass('selected');

        } else {

            parentBillItem.addClass('selected');

        }

        let toCalculate = $(this).parents('.bills-results').find('.selected .price-number');
        let calculateBills = 0;
        let calculateFee = 0;
        let calculateTotal = 0;
        toCalculate.each(function () {

            let price = parseFloat($(this).text());

            calculateBills = calculateBills + price;

            calculateFee = calculateBills * 0.48048048;
        })

        $('.calc-bills .price').text(calculateBills.toFixed(2))
        $('.calc-fee .price').text(calculateFee.toFixed(2))
        $('.calc-total .price').text((calculateFee + calculateBills).toFixed(2))

        if ($('.bill-item.selected').length) {

            $(this).parents('.content').find('.your-details .expand-block').addClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').fadeIn(300)

        } else {

            $(this).parents('.content').find('.your-details .expand-block').removeClass('expanded granted')
            $(this).parents('.content').find('.your-details .expand-area').fadeOut(300)

        }

    })



    $('#transfer-success-modal').on('hide.bs.modal', function () {

        let formId = $('.confirm-success-button').data('form-id');

        $('#' + formId + ' form').submit();


    })



    //#region WebCam
    if (typeof Webcam == 'object') {

        Webcam.set({
            // live preview size
            width: 170,
            height: 'auto',
            constraints: {
                facingMode: "environment"
            },
            // device capture size
            dest_width: 170,
            dest_height: 140,

            // final cropped size
            crop_width: 170,
            crop_height: 95,

            // format and quality
            image_format: 'jpeg',
            jpeg_quality: 90,

            // flip horizontal (mirror mode)
            flip_horiz: false,
        });
    }

    $(document).on('click', '.make-snapshot', function () {
        let ss = null;
        Webcam.snap(function (data_uri) {
            var raw_image_data = data_uri.replace(/^data\:image\/\w+\;base64\,/, '');
            $('.input-file-block').find('.file-image').css('background-image', 'url(' + data_uri + ')');
            $('.input-file-block').find('.file-image').addClass('selected');
            $('.input-file-block').find('input[type="hidden"]').val(raw_image_data).trigger('change');

            Webcam.reset();
        });
        $(this).text('Take Photo').addClass('take-picture').removeClass('make-snapshot')
    })

    $(document).on('click', '.take-picture', function () {
        let id = $(this).parents('.content').attr('id');
        Webcam.attach('#' + id + ' .file-image');

        $(this).text('Make Snapshot').addClass('make-snapshot').removeClass('take-picture')
    })

    $('#webcam-modal').on('hide.bs.modal', function () {

        Webcam.reset();
    })
    //#endregion

    //end Ready

}

export function moneyTransferCalculate(language) {
    $('#pay-point .local').on('click', function (e) {

        e.preventDefault();
        if (validateFields($(this).parents('form').find('input, select'))) {

            // $( this ).parents( 'form' ).find( '.calculate-result' ).removeClass( 'd-none' ).addClass( 'd-block' );
            if (language == "TR")
                $(this).val('Yeniden Hesapla');
            else
                $(this).val('Recalculate');
        }

    })
}


export function yourDetailsBlockGrant(granted) {
    
    if (granted) {

        let feeBlock = $(".fee-calculate").parents('.form-group, .form-wrapper').find('.fee');
        feeBlock.find('.result').text();
        feeBlock.fadeIn(300);

        $('.your-details .expand-block').addClass('granted')

    }
    else {
        let feeBlock = $(".fee-calculate").parents('.form-group, .form-wrapper').find('.fee');
        feeBlock.find('.result').text();
        feeBlock.hide(300);

        $('.your-details .expand-block').removeClass('granted')
        $('.your-details .expand-block').removeClass('expanded')
        $('.your-details .expand-area').hide(300)
    }

}

export function yourDetailsBlockExpand(expanded) {
    
    if (expanded) {

        $('.your-details .expand-block').addClass('expanded')
        $('.your-details .expand-area').show(300)

    }
    else {

        $('.your-details .expand-block').removeClass('expanded')
        $('.your-details .expand-area').hide(300)
    }
}

export function recipientBlockGrant(granted) {

    if (granted) {
        $('.recipient-block .expand-block').addClass('granted');
    }
    else {
        $('.recipient-block .expand-block').removeClass('granted');
        $('.recipient-block .expand-block').removeClass('expanded');
        $('.recipient-block .expand-area').hide(300);
    }
}

export function recipientBlockExpand(expanded) {
    
    if (expanded) {
        $('.recipient-block .expand-block').addClass('expanded');
        $('.recipient-block .expand-area').fadeIn(300);
    }

    else {
        $('.recipient-block .expand-block').removeClass('expanded');
        $('.recipient-block .expand-area').hide(300);
    }
}

export function cardDetailsBlockExpand(expanded) {

    if (expanded) {
        $('.card-details .expand-block').addClass('expanded')
        $('.card-details .expand-area').fadeIn(300)
    }
    else {
        $('.card-details .expand-block').removeClass('expanded')
        $('.card-details .expand-area').hide(300)
    }
}

export function cardDetailsBlockGrant(granted) {

    if (granted) {
        $('.card-details .expand-block').addClass('granted')
    }
    else {
        $('.card-details .expand-block').removeClass('granted')
        $('.card-details .expand-block').removeClass('expanded')
        $('.card-details .expand-area').hide(300)
    }
}

export function selectPicker() {
    $(".selectpicker").selectpicker();
}

export function resetMoneyTransfer() {
    $('.recipient-block .expand-block').removeClass('expanded granted');
    $('.recipient-block .expand-area').hide(300);

    $('.your-details .expand-block').removeClass('expanded granted')
    $(".yourdetail").parents('.content').find('.your-details .expand-area').hide(300)

    $('.card-details .expand-block').removeClass('expanded granted')
    $('.card-details .expand-area').hide(300)

    $('.submit-transfer .expand-block').removeClass('expanded granted')
    $('.submit-transfer .expand-area').hide(300)
}