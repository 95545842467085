import { Component, OnInit } from '@angular/core';
import { ApiRequest, ApiResponse, ServiceBase } from 'src/app/base';
import { BusinessUserApplyModel } from 'src/app/_models/BusinessUserApplyModel';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';
import { MessageModel } from 'src/app/_models/MessageModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'businessuser-userinfo',
    templateUrl: './businessuser-userinfo.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class BusinessUserUserInfoComponent implements OnInit {
    businessUserForm: FormGroup;

    constructor(public serviceBase: ServiceBase, public businessUserApply: BusinessUserApplyModel, private siteVariablesModel: SiteVariablesModel,
        private messageModel: MessageModel, public formBuilder: FormBuilder, private router: Router) {
        this.siteVariablesModel.setLanguage();
        this.businessUserForm = this.formBuilder.group({
            'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(60)])],
            'surname': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
            // 'tckn': [null, Validators.compose([Validators.required,Validators.minLength(11), Validators.maxLength(11)])],
            'gsmNumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
            'email': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(40), Validators.email])],
        });
    }

    ngOnInit() {
        this.businessUserForm.reset();
    }
}
