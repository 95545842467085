<header-navbar [activeComponent]="'moneytransfer'"></header-navbar>

<top-slider [activeSlide]="'payment-confirm'" [isBusiness]='true'></top-slider>

<div class="above-main-content">
  <div id="main-content" class="transfer-page" *ngIf="showReceipt">
    <div class="shadow d-none d-lg-block"></div>

    <div class="content transfer-confirmed">
      <div [ngClass]="{ in: false }" [ngStyle]="{ opacity: 1, position: 'relative' }">
        <div *ngIf="receiptDetail.SendingFeeFromRequester">
          <h3 class="text-darkgreen text-left">Tahsilat Bilgisi</h3>
        </div>
        <div *ngIf="!receiptDetail.SendingFeeFromRequester">
          <h3 class="text-darkgreen text-left">Tahsilat Dekontu</h3>
        </div>

        <div
          class="content-table d-flex justify-content-between justify-content-md-start align-items-center text-19 flex-wrap"
          id="receipt" #receipt>
          <div class="table-head-block text-black w-100">
            <!-- <div class="recipient-info-block text-main-blue"> -->
          </div>

          <div class="recipient-data-block text-black text-center text-sm-left w-100">
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">TARİH</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{
                  receiptDetail.TransactionDate | date: "dd/MM/yyyy HH:mm:ss"
                  }}</span>
              </div>
            </div>
            <div *ngIf="!receiptDetail.SendingFeeFromRequester">
              <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
                <div class="recipient-info-line text-main-blue">
                  <span class="">SERİ NO</span>
                </div>

                <div class="recipient-data-line recipient-name">
                  <span class="">{{ receiptDetail.SerialNumber }}</span>
                </div>
              </div>
              <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
                <div class="recipient-info-line text-main-blue">
                  <span class="">SIRA NO</span>
                </div>

                <div class="recipient-data-line recipient-name">
                  <span class="">{{ receiptDetail.SequenceNumber }}</span>
                </div>
              </div>
            </div>

            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">ALIM YERİ</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{ receiptDetail.PaymentTypeName }}</span>
              </div>
            </div>
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">GÖNDERİCİ AD / SOYAD</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{ receiptDetail.SenderNameSurname }}</span>
              </div>
            </div>
            <!-- <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">

                            <div class="recipient-info-line text-main-blue">
                                <span class="">GÖNDERİCİ TCKN</span>
                            </div>

                            <div class="recipient-data-line recipient-name">
                                <span
                                    class="">{{item.SenderTCKN.toString().substr(0,2) + '*****' + item.SenderTCKN.toString().substr(7,4)}}</span>
                            </div>


                        </div> -->
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">GÖNDERİCİ GSM NUMARASI</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{
                  receiptDetail.SenderGsmNumber.toString().substr(0, 4) +
                  "****" +
                  receiptDetail.SenderGsmNumber.toString().substr(8, 2)
                  }}</span>
              </div>
            </div>
            <!-- <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">

                            <div class="recipient-info-line text-main-blue">
                                <span class="">GÖNDERİCİ ADRES</span>
                            </div>

                            <div class="recipient-data-line recipient-name">
                                <span class="">{{item.SenderAddress}}</span>
                            </div>


                        </div> -->
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">ALICI AD / SOYAD </span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{ receiptDetail.ReceiverNameSurname }}</span>
              </div>
            </div>
            <!-- <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row" *ngIf="item.ReceiverTCKN != undefined">

                            <div class="recipient-info-line text-main-blue">
                                <span class="">ALICI TCKN </span>
                            </div>

                            <div class="recipient-data-line recipient-name">
                                <span
                                    class="">{{item.ReceiverTCKN.toString().substr(0,2) + '*****' + item.ReceiverTCKN.toString().substr(7,4)}}</span>
                            </div>


                        </div> -->
            <!-- <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row" *ngIf="item.ReceiverIBAN != undefined">

                                <div class="recipient-info-line text-main-blue">
                                    <span class="">ALICI IBAN </span>
                                </div>

                                <div class="recipient-data-line recipient-name">
                                    <span
                                        class="">TR{{item.ReceiverIBAN.toString().substr(0,4) + '**************' + item.ReceiverIBAN.toString().substr(20,4)}}</span>
                                </div>
                            </div> -->
            <!-- <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row" *ngIf="item.ReceiverIBAN != undefined && !item.IsForVerification">

                                <span
                                class="">Hafta sonu ve tatil günleri ile hafta içi saat 16:30’dan sonra yapılan IBAN’a gönderim işlemleri ertesi işgünü gerçekleşir.</span>
                            </div>
                            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row" *ngIf="item.ReceiverIBAN != undefined && item.IsForVerification">

                                <span
                                class="">Web sitemizden ilk olarak yapılan IBAN’a gönderim işlemleri ertesi işgünü gerçekleşir.</span>
                            </div> -->

            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row"
              *ngIf="receiptDetail.ReceiverGsmNumber != undefined">
              <div class="recipient-info-line text-main-blue">
                <span class="">ALICI GSM NUMARASI </span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{
                  receiptDetail.ReceiverGsmNumber.toString().substr(0, 4) +
                  "****" +
                  receiptDetail.ReceiverGsmNumber.toString().substr(8, 2)
                  }}</span>
              </div>
            </div>
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">REFERANS NO</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <span class="">{{ receiptDetail.ReferenceNumber }}</span>
              </div>
            </div>
            <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
              <div class="recipient-info-line text-main-blue">
                <span class="">GÖNDERİLEN TUTAR</span>
              </div>

              <div class="recipient-data-line recipient-name">
                <div *ngIf="receiptDetail.SendingFeeFromRequester">
                  <span class="">{{ receiptDetail.TotalAmount }} TL</span>
                </div>
                <div *ngIf="!receiptDetail.SendingFeeFromRequester">
                  <span class="">{{ receiptDetail.Amount }} TL</span>
                </div>
              </div>
            </div>
            <div *ngIf="!receiptDetail.SendingFeeFromRequester">
              <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
                <div class="recipient-info-line text-main-blue">
                  <span class="">KOMİSYON TUTARI </span>
                </div>

                <div class="recipient-data-line recipient-name">
                  <span class="">{{ receiptDetail.TotalCommissionAmount }} TL</span>
                </div>
              </div>

              <div class="recipient-block-row d-flex justify-content-start flex-column flex-sm-row">
                <div class="recipient-info-line text-main-blue">
                  <span class="">TOPLAM TUTAR </span>
                </div>

                <div class="recipient-data-line recipient-name">
                  <span class="">{{ receiptDetail.TotalAmount }} TL</span>
                </div>
              </div>
            </div>

            <!-- </div> -->
          </div>

          <div class="another-info-block w-100">
            <p class="text-blue mt-20">
              BİLGİLERİ KONTROL EDİNİZ VE DOĞRU OLDUĞUNDAN EMİN OLUNUZ.
            </p>
            <div class="ai-content">
              <p class="text-uppercase">GÖNDERAL ÖDEME HİZMETLERİ A.Ş.</p>
              <p>T.Sicil No : 532861</p>
              <p>Mersis No : 0622041430800011</p>
              <p>
                Hamidiye Mah. Selçuklu Cad. Vadipark A Blok No:10G 34408 Kağıthane, İstanbul
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div>
        <div class="button">
          <a class="buttonDefault button-red size26" href="https://gonder-al.com/businessuser" tabindex="0">Para Gönder</a>
        </div>
      </div> -->

      <!-- <div *ngIf="!receiptDetail.SendingFeeFromRequester">
        <h5 class="text-darkgreen">
          Dekontu emalinize göndermek ister misiniz?
        </h5>
      </div>
      <div *ngIf="receiptDetail.SendingFeeFromRequester">
        <h5 class="text-darkgreen">
          Emalinize göndermek ister misiniz?
        </h5>
      </div>

      <div class="form-block expand-area" style="display: block">
        <form
          [formGroup]="receiptMailForm"
          (ngSubmit)="
            sendReceiptviaMail(receiptMailForm.value, receiptMailForm.valid)
          "
        >
          <div class="form-wrapper">
            <label
              class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between"
            >
              E-mail:
              <div class="error-wrapper">
                <input
                  type="text"
                  name="transfer-confirmed-email"
                  formControlName="email"
                  placeholder="your@email.com"
                />
                <span class="error-message d-none"
                  >Lütfen mail alanını doldurunuz.</span
                >
              </div>
            </label>
          </div>

          <div class="button-block text-center text-lg-left mt-4">
            <div class="input-wrapper d-inline-flex">
              <input
                type="submit"
                value="Dekont Gönder"
                class="buttonDefault button-red validate-transfer-find"
              />
            </div>
          </div>
        </form>
      </div> -->
    </div>
    <!-- End Main Content -->
  </div>
  <!-- end of wrapper -->
</div>

<footer-template></footer-template>