export class PosModel {

    public CcOwnerName: string;
    public CcNumber: string;
    public Cvc: string;
    public ExpireYear: string;
    public ExpireMonth: string;
    public CreditLastDate: string;
    public Amount: number;
    public Installment: number;
    public OrderID: string;
    public IPAdress: string;
    public Email: string;
    public Telephone: string;
    public TransactionType: string;
    public Currency: string;
    public CardType: string;
    public Language: string;
    public TransactionChannelType: string;
    public IsBusinessUser: boolean;
    public BusinessUserCollectionRequestId: number;
}
