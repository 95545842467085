<form [formGroup]="companyForm">
    <div class="input-checkbox text-center text-lg-left">
        <div class="d-block d-lg-inline-block align-top text-center text-lg-left">
            <input type="checkbox" class="d-none" name="sameAsUp" id="tnc-checkbox-transfer"
                (change)="checkSameUpCheck($event)">
            <label for="tnc-checkbox-transfer" class="text-center">Bilgiler yukarıdakiler ile aynıdır.</label>
            <div class="error-wrapper" style="width: 74%;">
                <span class="error-message d-none">Lütfen kullanım koşullarını kabul
                    ediniz.</span>
            </div>
        </div>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['businesstitle'].valid && companyForm.controls['businesstitle'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Ticari Ünvan
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" formControlName="businesstitle" maxlength="25" class="inputDefault"
                    [(ngModel)]="this.businessUserApply.LegalName" upperCase>
                <span class="error-message d-none">Ticari Unvan alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Vergi Dairesi
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" formControlName="taxoffice" maxlength="25" class="inputDefault"
                    [(ngModel)]="this.businessUserApply.TaxOffice" upperCase>
                <span class="error-message d-none">Vergi dairesi alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['taxId'].valid && companyForm.controls['taxId'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Vergi No
            <div class="error-wrapper" style="width: 74%;">
                <input type="number" onKeyPress="if(this.value.length==11) return false;" class="inputDefault"
                    inputmode="numeric" id="inputBox" formControlName="taxId"
                    [(ngModel)]="this.businessUserApply.TaxId">
                <span class="error-message d-none">Vergi no alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['activitysubject'].valid && companyForm.controls['activitysubject'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Faaliyet Konusu
            <div class="error-wrapper" style="width: 74%;">
                <input type="text" formControlName="activitysubject" class="text-center text-lg-left" maxlength="35"
                    class="inputDefault" [(ngModel)]="this.businessUserApply.ActivitySubject" upperCase>
                <span class="error-message d-none">Faaliyet Konusu alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['estimateMonthlyCollection'].valid && companyForm.controls['estimateMonthlyCollection'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Tahmini Aylık Tahsilat Adedi
            <div class="error-wrapper" style="width: 74%;">
                <input type="number" onKeyPress="if(this.value.length==5) return false;" id="inputBoxCollect"
                    class="inputDefault" inputmode="numeric" formControlName="estimateMonthlyCollection"
                    [(ngModel)]="this.businessUserApply.EstimateMonthlyCollection">
                <span class="error-message d-none">Lütfen zorunlu alanları doldurunuz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['estimateMonthlyShipping'].valid && companyForm.controls['estimateMonthlyShipping'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Tahmini Aylık Gönderim Adedi
            <div class="error-wrapper" style="width: 74%;">
                <input type="number" onKeyPress="if(this.value.length==5) return false;" id="inputBoxShipping"
                    class="inputDefault" inputmode="numeric" formControlName="estimateMonthlyShipping"
                    [(ngModel)]="this.businessUserApply.EstimateMonthlyShipping">
                <span class="error-message d-none">Lütfen zorunlu alanları doldurunuz.</span>
            </div>
        </label>
    </div>

    <div class="form-wrapper"
        [ngClass]="{'error':(!companyForm.controls['description'].valid && companyForm.controls['description'].touched)}">
        <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            Açıklama
            <div class="error-wrapper" style="width: 74%;">
                <textarea formControlName="description" class="w-100"
                    placeholder="Lütfen işletmeniz ve GönderAL sistemini kullanma amacınız hakkında bilgi veriniz."
                    maxlength="4000" class="inputDefault" [(ngModel)]="this.businessUserApply.Description"
                    upperCase></textarea>
                <span class="error-message d-none">Açıklama alanı boş bırakılamaz.</span>
            </div>
        </label>
    </div>
</form>