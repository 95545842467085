<header-navbar [activeComponent]="'moneytransfer'"></header-navbar>
<top-slider [activeSlide]="'businessuser-collection'"></top-slider>

<!-- Content -->
<div class="above-main-content">
    <!-- Payment select box -->
    <div id="main-content" class="transfer-page" style="min-height: 485px;">
        <div class="shadow d-none d-lg-block" [ngClass]="{'hide': siteVariablesModel.language != 'TR'}"></div>

        <!-- Bank Account -->
        <div class="content payment-item collection" [ngClass]="{'hide': siteVariablesModel.language != 'TR'}"
            *ngIf="this.collectionDetail">
            <!-- Blue Block -->
            <!-- <form [formGroup]="calculateDomesticForms"> -->

            <div class="map-search blue-block">
                <div class="row mr-0 ml-0">
                    <div class="col-12 pl-0 pr-1">
                        <h4 class="text-center text-lg-left">Para Gönder</h4>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="ta-pp" class="col-12 col-lg-3 col-form-label white">Gönderim Tutarı (TL)</label>
                    <div class="col-12 col-lg-9 error-wrapper">
                        <input type="number" name="text" class="form-control" [(ngModel)]="this.collectionDetail.Amount"
                            max="19999">
                    </div>
                </div>
                <div class="form-group row" *ngIf="!this.collectionDetail.SendingFeeFromRequester">
                    <div class="col-lg-9 col-12 text-center text-lg-left ml-auto blueform-btn">
                        <p class="fee">Ücret = <span class="result">{{this.totalCommission}}</span>
                            TL </p>

                        <div class="button d-md-inline-block fee-calculate error-wrapper">
                            <span class="calculate-button">
                                <input type="button" (click)="calculateCommission()" value="Hesapla"
                                    class="buttonDefault button-red local">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <div class="col-12 col-lg-3 col-form-label">
                          <p class="fee">Komisyon Tutarı =</p>
                        </div>
                        <div class="col-12 col-lg-9 col-form-label">
                          <p class="fee"><span class="result">{{ this.totalComission }}</span> TL</p>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12 col-lg-3 col-form-label">
                          <p class="fee">Toplam Tutar =</p>
                        </div>
                        <div class="col-12 col-lg-9 col-form-label">
                          <p class="fee"><span class="result">{{ this.totalAmount }}</span> TL</p>
                        </div>
                      </div> -->
                </div>

            </div><!-- End Blue Block -->
            <!-- </form> -->
            <div class="blue-block-triangle"></div>
            <!-- Bills Results -->
            <!-- <form> -->
            <div class="content-smaller">

                <!-- START Your Details -->
                <div class="your-details mx-auto">
                    <div class="your-details-wrapper expand-block">
                        <div class="your-details-title border-bottom-grey">
                            <h5 class="text-center text-lg-left position-relative expand-button">Gönderici Bilgileri
                            </h5>
                        </div>
                        <div class="form-block expand-area display">
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    İsim
                                    <div class="error-wrapper">
                                        <input type="text" [(ngModel)]="this.collectionDetail.NameSurname" upperCase
                                            disabled>
                                    </div>
                                </label>
                            </div>
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    Email
                                    <div class="error-wrapper">
                                        <input type="text" [(ngModel)]="this.collectionDetail.Email" upperCase disabled>
                                    </div>
                                </label>
                            </div>
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    GSM No
                                    <div class="error-wrapper">
                                        <input type="text" [(ngModel)]="this.collectionDetail.GsmNumber" upperCase
                                            disabled>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Your Details -->

                <!-- START Payment Details -->
                <div class="recipient-block mx-auto">
                    <div class="recipient-block-wrapper expand-block">
                        <div class="recipient-block-title border-bottom-grey">
                            <h5 class="text-center text-lg-left position-relative expand-button">Alıcı Bilgileri</h5>
                        </div>
                        <div class="form-block expand-area display">
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    İsim
                                    <div class="error-wrapper">
                                        <input type="text" [(ngModel)]="this.collectionDetail.ReceiverName" upperCase
                                            disabled>
                                    </div>
                                </label>
                            </div>
                            <!-- <div class="form-wrapper">
                              <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                  Not
                                  <div class="error-wrapper">
                                    <input type="text"
                                          [(ngModel)]="this.collectionDetail.Note"
                                          upperCase disabled >
                                  </div>
                              </label>
                            </div> -->
                            <!-- <div class="form-wrapper">
                              <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                  Tahsilat Tarihi
                                  <div class="error-wrapper">
                                    <input type="date"
                                          [ngModel]="this.collectionDetail.CollectionDate | date:'yyyy-MM-dd'"
                                          disabled >
                                  </div>
                              </label>
                            </div> -->
                            <!-- <div class="form-wrapper">
                              <label class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                  Doküman
                                  <div class="error-wrapper">
                                    <input type="text"
                                          [(ngModel)]="this.collectionDetail.Document"
                                          upperCase disabled >
                                  </div>
                              </label>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- END Payment Details -->

                <!-- START Card Details -->
                <div class="card-details mx-auto">
                    <div class="card-details-wrapper expand-block">
                        <div class="your-details-title border-bottom-grey">
                            <h5 class="text-center text-lg-left position-relative expand-button">
                                Kredi Kartı Bilgileri</h5>
                        </div>
                        <div class="form-block expand-area display">
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    Kart Sahibinin Adı
                                    <div class="error-wrapper">
                                        <input type="text" placeholder="" upperCase [(ngModel)]="cardInfos.CcOwnerName">
                                        <!-- <span class="error-message ">Lütfen zorunlu alanları doldurunuz.</span> -->
                                    </div>
                                </label>
                            </div>
                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    Kart No
                                    <div class="error-wrapper">
                                        <input type="text" mask="0000 0000 0000 0000" placeholder="XXXX XXXX XXXX XXXX"
                                            [(ngModel)]="cardInfos.CcNumber">
                                        <!-- <span class="error-message ">Lütfen zorunlu alanları doldurunuz.</span> -->
                                    </div>
                                </label>
                            </div>

                            <div class="form-wrapper">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    Geçerlilik Tarihi
                                    <div class="error-wrapper">
                                        <input type="text" mask="00/00" placeholder="XX/XX"
                                            class="text-center text-lg-left" [(ngModel)]="cardInfos.CreditLastDate">
                                        <!-- <span class="error-message ">Lütfen zorunlu alanları doldurunuz.</span> -->
                                    </div>
                                </label>
                            </div>

                            <div class="form-wrapper validate-bank-card">
                                <label
                                    class="text-blue d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
                                    CVC
                                    <div class="error-wrapper">
                                        <input type="text" mask="000" placeholder="XXX" [(ngModel)]="cardInfos.Cvc">
                                        <!-- <span class="error-message ">Lütfen zorunlu alanları doldurunuz.</span> -->
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Card Details -->

                <!-- Submit Transfer -->
                <div class="submit-transfer mx-auto">

                    <div class="submit-transfer-wrapper expand-block">

                        <div class="submit-transfer-title border-bottom-grey">

                            <h5 class="text-center text-lg-left position-relative expand-button">
                                İşlemi Tamamla</h5>
                        </div>

                        <!-- <form action="#" method="post" onsubmit="event.preventDefault();"> -->

                        <div class="form-block expand-area display">

                            <div
                                class="form-wrapper d-flex flex-wrap justify-content-center justify-content-lg-between file-upload-block">

                                <div class="label text-blue flex-wrap">

                                </div>

                                <div class="input-checkbox text-center text-lg-left">

                                    <div class="d-block d-lg-inline-block align-top text-center text-lg-left">
                                        <input type="checkbox" class="d-none" name="tnc_agree"
                                            id="tnc-checkbox-transfer-bank" [(ngModel)]="isAcceptTerms">
                                        <label for="tnc-checkbox-transfer-bank" class="text-center">GönderAl <a href="#"
                                                data-toggle="modal" data-target="#tnc-modal-contract">kullanım
                                                koşullarını kabul ediyorum. </a></label>

                                        <div class="error-wrapper">
                                            <span class="error-message d-none">Lütfen kullanım koşullarını kabul
                                                ediniz.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-block text-center text-lg-left mt-4 mt-lg-0">
                                <div class="input-wrapper d-inline-flex">
                                    <input type="button" value="Gönder" (click)="makeRoutingTransfer()"
                                        class="size26 confirm-form  buttonDefault button-red">
                                </div>
                            </div>
                        </div><!-- End Form Block -->
                    </div>

                </div>



                <!-- END Submit Transfer -->
            </div>

            <!-- </form> -->
            <!-- Form end -->
        </div> <!-- Content -->
        <!-- END Bank Account -->
        <div class="img-rounded" style="position: fixed;  top:30%; left:48%; z-index: 9999;" *ngIf="Loading">
            <img src="../../assets/gif/loading.gif" [ngClass]="{'disabled':  Loading }" />
        </div>
    </div><!-- End Main Content -->
</div><!-- end of wrapper -->

<footer-template></footer-template>



<div class="modal fade" id="tnc-modal-contract" tabindex="-1" role="dialog" aria-labelledby="Е7С" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h3>TEK SEFERLİK ÖDEME HİZMETİ SÖZLEŞMESİ</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span i18n="@@close">Close</span> -->
                    <span aria-hidden="true">
                        <img src="./assets/img/cross.png" alt="Close" i18n-title title="Close">
                    </span>
                </button>
            </div>

            <div class="modal-body">

                <p>
                    İşbu Tek Seferlik Ödeme Sözleşmesi ( <strong>“Sözleşme”</strong>), Ödeme Hizmetleri ve Elektronik
                    Para
                    İhracı İle Ödeme Kuruluşları ve Elektronik Para Kuruluşları Hakkında Yönetmelik’in 29.
                    maddesi uyarınca, GönderAL Ödeme Hizmetleri Anonim Şirketi ( <strong>“Ödeme
                        Kuruluşu”</strong>) ile ödeme hizmeti kullanıcısı (<strong>“Kullanıcı”</strong>) arasında
                    aşağıda yer alan ödeme
                    işlemlerinin ve hizmetlerinin gerçekleşmesi amacıylaimzalanmıştır.
                </p>
                <p>
                    6493 Sayılı Ödeme ve Menkul Kıymet Mutabakat Sistemleri, Ödeme Hizmetleri ve Elektronik
                    Para Kuruluşları Hakkında Kanun (<strong>“Kanun’’</strong>) kapsamındaki ödeme işlemlerinin
                    gerçekleştirilebilmesi için <strong>Kullanıcı</strong> tarafından aşağıdaki bilgilerin Ödeme
                    Kuruluşu’na
                    sağlanması gerekmektedir.
                </p>
                <h5>1. Gönderen’e ilişkin Bilgiler</h5>
                <p>
                    Ad-Soyad : <br> <br>
                    T.C. Kimlik / Yabancı Kimlik / Vergi Kimlik No : <br> <br>
                    Telefon No : <br> <br>
                    Elektronik Posta Adresi : <br> <br>
                    Adres : <br> <br>
                    Kredi Kartı Numarası : <br> <br>
                </p>

                <h5>2. Alıcı’ya ilişkin Bilgiler</h5>
                <p>
                    Ad-Soyad / Kurum İsmi : <br> <br>
                    T.C. Kimlik / Yabancı Kimlik / Vergi Kimlik No : <br> <br>
                    İletişim Bilgileri : <br> <br>
                    Banka : <br> <br>
                    IBAN/ Hesap No / Kredi Kartı No : <br> <br>
                    Abone / Tesisat No : [Fatura ödemeleri için] <br> <br>
                </p>

                <h5>3. İşlem Türü : </h5>

                <h5>4. İşlem Tutarı : </h5>

                <h5>5. İşlemin Tamamlanma Süresi</h5>
                <p>
                    Yurt içi IBAN’a gönderimlerde; iş günlerinde saat
                    16.00’ya kadar yapılan işlemler aynı gün, sonrasında veya iş günü dışındaki günlerde
                    yapılanlar ise takip eden ilk iş günü EFT saatleri içerisinde gerçekleştirilir.
                </p>
                <p>
                    Yurt içi ödeme noktalarımız ile yurt dışına yapılan transferler ise 10 dk içerisinde
                    sistemde,alıcının kullanımına hazır hale gelir. Bu süreler ödeme tutarının Alıcı’nın
                    ödeme hizmeti sağlayıcısına ulaşması için geçecek sürelerdir. Ödeme tutarının
                    Alıcı’ya ulaştırmasına ilişkin tüm sorumluluk Alıcı’nın ödeme hizmeti sağlayıcısına
                    aittir.
                </p>

                <h5>6. Ücret Bilgileri ve Ücret Dökümü</h5>
                <p>
                    İşlem Ücreti : <br> <br>
                    Vergi : <br> <br>
                    Toplam Ücret : <br> <br>
                </p>

                <h5>7. Döviz Kuru/Referans Döviz Kuru Bilgileri:</h5>
                <p>
                    Ödeme Kuruluşu, ödeme işlemleri sırasında alınan işlem komisyonlarına ek olarak işlemlere
                    döviz kuru uygulanması durumunda kur farklarından doğacak farklardan da gelir
                    sağlamaktadır. Ödeme işlemine uygulanan döviz kurunun piyasa kurundan farklı olması
                    durumunda, ortaya çıkacak fark, Ödeme Kuruluşu tarafından gelir kaydedilecektir.
                </p>

                <h5>8. Bilgilerin Doğruluğu</h5>
                <p>
                    Kullanıcı; ödeme işlemi ile ilgili verdiği tüm bilgilerin (Alıcı'ya ilişkin olarak vermiş olduğu tüm
                    bilgiler dâhil olmak üzere) doğru olduğunu, söz konusu bilgilerin hatalı veya eksik
                    olmasından kaynaklanan her türlü doğrudan ve/veya dolaylı zararın kendisine ait olacağını
                    kabul ve beyan eder. Kullanıcı tarafından verilen bilgilerin eksik veya hatalı olması halinde
                    Ödeme Kuruluşu, ödeme işleminin gerçekleştirilmemesinden veya hatalı
                    gerçekleştirilmesinden sorumlu tutulamaz.
                </p>

                <h5>9. Ödeme Emri</h5>
                <p>
                    Kullanıcı tarafından ödeme işleminin gerçekleştirilmesine ilişkin talimat (<strong>“Ödeme
                        Emri”</strong>)
                    Ödeme Kuruluşu’na ulaştığında Ödeme Kuruluşu yetkilendirilmiş sayılır.
                    Kullanıcı’nın verdiği Ödeme Emri’ne uygun olarak gerçekleşen ödeme işlemlerinin tüm
                    sonuçlarından tamamen Kullanıcı sorumludur. Kullanıcı bu durumlarda herhangi bir zarar
                    iddiasında bulunamaz ve Ödeme Kuruluşu’na herhangi bir talep ileri süremez.
                </p>

                <h5>10. Ödeme Belgesi</h5>
                <p>
                    Uzaktan iletişim aracı ile gerçekleştirilen tek seferlik ödeme işlemlerinde dekont,
                    Kullanıcı’nın sisteme tanımladığı elektronik posta adresine iletilir. Kullanıcı’nın, işleme ilişkin
                    referans ve adres bilgilerini talep etmesi halinde, ilgili dekont Kullanıcı’nın adresine kağıt
                    üzerinde olarak da iletilir. Bu durumda posta ücreti Kullanıcı’ya aittir.
                    Ödeme noktasında gerçekleştirilen ödeme hizmeti işlemlerinde dekontun bir nüshası
                    Kullanıcı’ya teslim edilir.
                </p>

                <h5>11. Bilgilerin Korunması ve Yetkisiz Kullanım</h5>
                <p>
                    Kullanıcılar tarafından ödeme emrinin verilmesinde veya kullanıcı kimliğinin
                    doğrulanmasında kullanılan, ele geçirilmesi veya değiştirilmesi halinde dolandırıcılık ya da
                    kullanıcılar adına sahte işlem yapılmasına imkan verebilecek şifre, güvenlik sorusu, sertifika,
                    şifreleme anahtarı ile PIN, kart numarası, son kullanma tarihi, CVV2, CVC2 kodu gibi
                    kuruluşlar tarafından ihraç edilen ödeme araçlarına ilişkin kişisel güvenlik bilgileri “Hassas
                    Ödeme Verileri”dir.
                </p>
                <p>Kullanıcı tarafından işlem sırasında Ödeme Kuruluşu ile paylaşılan Hassas Ödeme Verileri
                    ve diğer bilgiler, ödeme hizmetinin sunulması ve buna ilişkin işlerin yönetilmesi amacıyla
                    saklanmakta ve kullanılmaktadır. Bu bilgiler, işlemin gerçekleştirilmesi için Ödeme
                    Kuruluşu’nun iş ortakları,muhabirleri, fatura üreten kurumlar vb gibi işlemin yapılması için
                    gerekli olan kişilerle paylaşılabilir.</p>
                <p>Kullanıcı tarafından Ödeme Kuruluşu’na bildirilen Hassas Ödeme Verileri dışındaki bilgiler,
                    mevzuatın izin verdiği ölçüde ve Kullanıcı’nın sistemde izin vermesi kaydıyla, Ödeme
                    Kuruluşu tarafından tanıtım, reklam, kampanya, promosyon, duyuru, mesaj gönderme vb.
                    gibi pazarlama faaliyetleri kapsamında kullanılabilecektir</p>
                <p>Ödeme Kuruluşu, hassas ödeme verilerinin herhangi bir kısmının kamu alanına girmesini
                    veya yetkisiz kullanımını veya yetkisiz kişilere ifşasını önlemek için gerekli tedbirleri almayı
                    ve gerekli özeni göstermeyi taahhüt etmektedir. Ödeme Kuruluşu’nun gerekli bilgi güvenliği
                    önlemlerini almasına karşın web sitesine ve sisteme yapılan saldırılar sonucunda gizli
                    bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda, Ödeme
                    Kuruluşu’nun herhangi bir sorumluluğu olmayacaktır.
                </p>
                <p>Kullanıcı, Ödeme Kuruluşu’nun usulsüzlük ve/veya dolandırıcılık şüphesi oluşması halinde,
                    veya yetkili kamu otoritelerinin talebi halinde, bilgilerinin, adli ve/veya idari mercilerle
                    ve/veya Resmi Kurumlar ile paylaşabileceğini kabul ve beyan eder.</p>
                <p>Kullanıcı, ödeme işlemi ile ilgili bilgilerin (referans numarası, alıcı bilgileri vs.) korunmasına ve
                    üçüncü kişilerle paylaşılmamasına yönelik gerekli önlemleri almayı kabul ve beyan eder. Söz
                    konusu bilgilerin Kullanıcı tarafından korunmaması nedeniyle ya da Kullanıcı’nın kusurundan
                    kaynaklanan nedenlerle ödeme işleminin hileli veya yetkisiz olarak gerçekleşmesi halinde,
                    Kullanıcı, söz konusu işlemden kaynaklanan her türlü doğrudan ve/veya dolaylı zararın
                    kendisine ait olacağını kabul ve beyan eder.</p>

                <h5>12. Ödeme İşleminin Geri Alınması</h5>
                <p>
                    Kullanıcı, Ödeme Kuruluşu’na vermiş olduğu Ödeme Emri’ni Ödeme Kuruluşu’nun
                    muvafakati olmaksızın geri alamaz.
                </p>

                <h5>13. Fikri Mülkiyet Hakları</h5>
                <p>
                    Kullanıcı, Ödeme Kuruluşu’nun web sitesinde ve sair evrakında yer alan ünvan, işletme adı,
                    marka, patent, logo, tasarım, kod, bilgi ve yöntem gibi tescilli veya tescilsiz fikri mülkiyet
                    haklarına ilişkin hiçbir unsuru kopyalamamayı, çoğaltmamayı, işlememeyi, dağıtmamayı
                    veya bunlardan türemiş çalışmalar yapmamayı veya hazırlamamayı ve hiçbir şekilde anılan
                    fikri mülkiyet haklarını ihlal etmemeyi kabul ve taahhüt eder.
                </p>

                <h5>14. Uyuşmazlıkların Çözümü</h5>
                <p>
                    İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak her türlü
                    uyuşmazlığın çözümünde İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri
                    yetkilidir.
                </p>
                <p>
                    <strong>İşbu sözleşmeyi imzalamaya ve yukarıda belirtilen işlemi yapmaya ehil olduğumu, yukarıdaki
                        bilgi ve hükümler uyarınca işlem yapılmasını kabul ettiğimi beyan ederim.</strong>
                </p>

                <p>
                    <strong>KULLANICI</strong> <br> <br>

                    Adı,Soyadı/Unvanı: <br> <br>
                    Tarih : <br> <br>
                    İmza : <br> <br>
                    <strong>ÖDEME KURULUŞU</strong> <br> <br>
                    İşlemi Yapan Yetkili : <br> <br>
                    Kaşe/İmza :
                </p>



            </div>
        </div>
    </div>
    <!-- End T&C Modal -->
</div>