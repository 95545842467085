import { Component, OnInit } from '@angular/core';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';

@Component({
    templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit {

    constructor(public siteVariablesModel: SiteVariablesModel) {
        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {
    }
}