<!-- Success Modal -->
<div class="modal fade" id="request-success" tabindex="-1" role="dialog" aria-labelledby="Е7С" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <h3>{{messageModel.messageHeader}}</h3>
                <p>{{messageModel.messageContent}}</p>
            </div>
        </div>
    </div>
</div>
<!-- Success Modal -->
<!-- Error Modal -->
<div class="modal fade" id="request-error" tabindex="-1" role="dialog" aria-labelledby="Е7С" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <h3>{{messageModel.messageHeader}}</h3>
                <p>{{messageModel.messageContent}}</p>
                <a i18n="@@close" href="#" class="buttonDefault button-red size26" data-dismiss="modal"
                    aria-label="Close">Close</a>
            </div>
        </div>
    </div>
</div>
<!-- Error Modal -->