export class SiteVariablesModel {
    public branches: any[];
    public language: string = "TR";
    public englishHref = window.location.origin + "/en";
    public turkishHref = window.location.origin;

    setLanguage() {
        if (window.location.href.startsWith(this.englishHref)) {
            this.language = "EN";
        }
        else {
            this.language = "TR";
        }
    };

    getClientBrowserDetail() {

        $.ajax('http://ip-api.com/json')
            .then(
                function success(response) {

                    if (response.country != "Turkey" && window.location.href.startsWith(this.turkishHref)) {
                        window.location.href = this.englishHref;
                    }
                },

                function fail(data, status) {
                    console.log('Request failed.  Returned status of',
                        status);
                }
            );

    }
}