import { Component, OnInit, Input } from '@angular/core';
import * as Script from '../scripts/script';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';
import { PaymentConfirmComponent } from 'src/app/PaymentConfirm/payment-confirm.component';
import { PaymentConfirmBusinessComponent } from 'src/app/PaymentConfirmBusiness/payment-confirm-business.component';
import { fromEvent } from 'rxjs';
import { map, sampleTime } from 'rxjs/operators';

@Component({
  selector: 'top-slider',
  templateUrl: './top-slider.component.html',

})
export class TopSliderComponent implements OnInit {

  @Input() activeSlide: string;
  @Input() isBusiness: boolean | false;

  constructor(
    public siteVariablesModel: SiteVariablesModel,
    public confirmComponent: PaymentConfirmComponent,
    public confirmBusinessComponent: PaymentConfirmBusinessComponent) {
    this.siteVariablesModel.setLanguage();
  }

  ngOnInit() {

    setTimeout(() => {
      Script.slickFunction();
      //Script.setCurrentSlide(this.activeSlide);
    }, 0);

  }

  downloadReceiptPDFFile() {
    if (!this.isBusiness) {
      this.confirmComponent.downloadReceiptPDFFile();
    } else {
      this.confirmBusinessComponent.downloadReceiptPDFFile();
    }
  }

  howItWorks() {
    const tracker = document.querySelector('.payment-item'); // get your element by css class
    const scroll$ = fromEvent(tracker, 'scroll').pipe(
      sampleTime(300), // to prevent getting multiple values in a milisecond
      map(() => tracker.scrollTop)
    );
    // let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
    //   console.log(scrollPos); // get scroll position
    //   // you can do your check here
    // });
  }

}
