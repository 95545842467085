import { Component, OnInit, Input } from '@angular/core';
import { MessageModel } from 'src/app/_models/MessageModel';

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',

})
export class MessageBoxComponent implements OnInit {

    constructor(public messageModel: MessageModel) {
        messageModel.messageContent = "";
        messageModel.messageHeader = "";
    }

    ngOnInit() {
    }

}