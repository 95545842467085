import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ApiRequest, ApiResponse, ServiceBase } from 'src/app/base';
import { ActivatedRoute } from '@angular/router';
import { SiteVariablesModel } from 'src/app/_models/SiteVariablesModel';

import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';

import { } from 'google-maps';
declare var google;

@Component({
    templateUrl: './paypointsall.component.html',
    providers: [ApiRequest, ApiResponse, ServiceBase],
})
export class PayPointsAllComponent implements OnInit {
    markers: any = [];
    lat: number = 41.109337;
    lng: number = 29.015669;
    showBranch: boolean = true;
    constructor(public serviceBase: ServiceBase, private route: ActivatedRoute, public branchModel: SiteVariablesModel, private mapsAPILoader: MapsAPILoader,) { }
    ngOnInit() {
        var x = "Ödeme Noktaları";
    }
    selectAllBranches() {

        if (this.branchModel.branches == undefined || this.branchModel.branches.length < 1) {
            var request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "BranchController/SelectActiveBranchDetail");
            this.serviceBase.Execute(request).then(() => {
                this.branchModel.branches = this.serviceBase.apiResponse.ResponseData.ActiveBranchResults;
                this.markers = this.branchModel.branches;
                this.mapsAPILoader.load().then(() => {
                    const center = new google.maps.LatLng(this.lat, this.lng);
                    this.branchModel.branches.forEach(function (part, index) {
                        const markerLoc = new google.maps.LatLng(this[index].MapCoordinateX, this[index].MapCoordinateY);
                        const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center) / 1000;
                        this[index].Distance = distanceInKm;
                    },
                        this.branchModel.branches);
                    this.branchModel.branches = this.transform(this.branchModel.branches, "Distance");
                });
            });
        }
        else
            this.markers = this.branchModel.branches;
    }
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
    }
}
