import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './mobileapp.component.html',
})
export class MobileAppComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}