export class MessageModel {
    public messageHeader: string;
    public messageContent: string;

    showSuccess(messageHeader, messageContent) {
        this.messageHeader = messageHeader;
        this.messageContent = messageContent;

        $('#request-success').modal('show');

        setTimeout(function () {
            $('#request-success').modal('hide');
        }, 4000);
    };

    showError(messageHeader, messageContent) {
        this.messageHeader = messageHeader;
        this.messageContent = messageContent;

        $('#request-error').modal('show');

    }

}
