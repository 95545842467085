export class DomesticMoneyTransferModel {
    public Amount: number;
    public CommissionAmount: number = 0;
    public BSMVAmount: number = 0;
    public TotalCommissionAmount: number = 0;
    public TotalAmount: number = 0;
    public SenderName: string;
    public SenderSurname: string;
    public SenderOccupation: string = "";
    public SenderReceiverRelationship: string = "";
    public PurposeofTransaction: string = "";
    public SenderTCKN: string;
    public SenderBirthDate: Date;
    public SenderGSMNumber: string;
    public SenderAddress: string;
    public SenderSecondAddress: string;
    public CityId: string = "";
    public DistrictId: string = "";
    public ZipCode: string;
    public ReceiverName: string;
    public ReceiverSurname: string;
    public ReceiverOccupation: string = "";
    public ReceiverGSMNumber: string;
    public Occupations: any = [];
    public PurposeOfTransactions: any = [];
    public RelationshipstoSender: any = [];
    public TransactionSessionId: string;
    public ReceiverLocation: string;
    public PaymentType: string = "";
    public ReceiverIBAN: string = "TR";
    public ReceiverTCKN: string;
    public ReceiverBirthDate: Date;
    public TransactionDate: string;
    public ReferenceNumber: string;
    public IsCustomerVerified: boolean;
    public IsVerifyTransaction: boolean = false;
    public SenderEmail: string;
    public SenderTcknValidationStatus: boolean = false;
    public ReceiverTcknValidationStatus: boolean = false;
    public ReceiverType: number;
}