import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UppercaseModule } from '../uppercase/uppercase.module';
import { MoneyTransferReceiverDetailsComponent } from './moneytransfer-receiverdetails.component';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UppercaseModule,
    NgxMaskModule.forRoot(),
    RouterModule
  ],
  declarations: [MoneyTransferReceiverDetailsComponent],
  exports: [MoneyTransferReceiverDetailsComponent, CommonModule],
  providers: []
})
export class MoneyTransferReceiverDetailsModule { }