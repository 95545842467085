import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UppercaseModule } from '../uppercase/uppercase.module';
import { MoneyTransferYourDetailsComponent } from './moneytransfer-yourdetails.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UppercaseModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [MoneyTransferYourDetailsComponent],
  exports: [MoneyTransferYourDetailsComponent, CommonModule],
  providers: []
})
export class MoneyTransferYourDetailsModule { }